import React, { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import { OutboundweightIcon } from "../../components/icons";
import moment from "moment";
import "./styles.scss";
import io from "socket.io-client";
import PendingOrders from "./partials/pending-orders";
import _ from "lodash";
import PendingPayments from "../Orders/partials/peding-payments";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
let socket = null;

const urls = ["http://localhost:3666", "https://relibit.weighworks.com", "https://stg-relibit.weighworks.com"];
const [selectedWeight, inboundWeights, outboundWeights] = [
  urls.indexOf(window.location.origin) !== -1 ? _.sample([5000, 6000, 7000, 8000]) : 0,
  urls.indexOf(window.location.origin) !== -1 ? [5000, 6000, 7000, 8000] : [],
  urls.indexOf(window.location.origin) !== -1 ? [1000, 2000, 3000, 4000] : []
];

export default class ScaleComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      weightType: "Inbound",
      weights: [],
      inboundWeights,
      outboundWeights,
      selectedWeight,
      user: null,
      toggleMenu: false,
      startDate: "",
      endDate: "",
      manuallyEnterWeight: false,
      scales: [],
      selectedScale: '',
      scaleWeights: [],
      weight: '',
      scaleId: '',
      rerender:false,
      pending_payment_orders: [],
      is_pending_payment_show: false
    };
  }

  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    document.title = 'Scale | Weighworks';
    const {
      history: {
        location: { state },
      },
    } = this.props;
    if (state) {
      const { from, to } = state;
      await this.setAsyncState({
        startDate: moment(from, 'MMM DD, YYYY').startOf('day').toISOString(),
        endDate: moment(to, 'MMM DD, YYYY').endOf('day').toISOString(),
        weightType: 'Outbound',
      });
    }
    this.getScales();
    socket = io.connect(`${HOSTNAME}`);
    this.connectScale();
    socket.on('get:weights', (data) => {
      const { companyId } = this.state;
      
      if (
        String(companyId) === String(data.companyId) &&
        !this.state.manuallyEnterWeight
      ) {
        const selectedWeightdata = this.state.scales;
        const index = selectedWeightdata.findIndex(scale => {
          return String(scale._id) === String(data.scaleId) && !scale.manuallyEnterWeight
        })
        if(index !== -1) {
          selectedWeightdata[index].weight = data.weight;
        }
        this.setState({ selectedWeight: data.weight });
        this.setState({ scaleId: data.scaleId });
        this.setState({ scaleWeights: selectedWeightdata });
        this.setState({rerender:!this.state.rerender})
      }
    });
  };

  getScales = async () => {
    const {
      value: {
        data: { scales },
      },
    } = await this.props.getScale();
    this.setState({ scales });
    const scaledata=scales
     scaledata && scaledata.forEach((scale) => {
      if (!scale.weightType) {
        scale['weightType'] = 'Inbound';
      }
      if (!scale.manuallyEnterWeight) {
        scale['manuallyEnterWeight'] = false;
      }
      if (!scale.weight) {
        scale['weight'] = 0;
      }
    });
    this.setState({ scaleWeights:scaledata });
  };

  manuallyEnterWeight = (weight, scaleId) => {
    const scaleWeights = this.state.scaleWeights;
    scaleWeights.forEach((scale) => {
      if (String(scaleId) === String(scale._id)) {
        scale.weight = weight;
      }
    });
    this.setState({ scaleWeights: scaleWeights });
    this.setState({ weight: weight });
  };

  isManallyEnterWeight = (ismanuallyEnterWeight, scaleId) => {
    const scaleWeights = this.state.scaleWeights;
    scaleWeights.forEach((scale) => {
      if (String(scaleId) === String(scale._id)) {
        if (!scale.manuallyEnterWeight) {
          scale['manuallyEnterWeight'] = false;
        }
        scale.manuallyEnterWeight = ismanuallyEnterWeight;
      }
    });
    this.setState({ scaleWeights: scaleWeights });
    this.setState({ manuallyEnterWeight: ismanuallyEnterWeight });
  };

  setWeightType = (value, scaleId) => {
    const scaleWeights = this.state.scaleWeights;
    scaleWeights.forEach((item) => {
      if(value==="Outbound"){
      if (String(item._id) === String(scaleId)) {
        item.weightType = value;
      } else {
        item.weightType = 'Inbound';
      }}else{
        if (String(item._id) === String(scaleId)) {
          item.weightType = value;
        }
      }
    });
    this.setState({ scaleWeights: scaleWeights });
    this.setState({ weightType: value });
    this.setState({ scaleId: scaleId });
  };

  connectScale = async () => {
    const {
      value: { user }, 
    } = await this.props.fetchMe();
      this.setState({ companyId: user.company._id, user });
      socket.emit('connect:scale', user.company._id);
  };

  componentWillUnmount() {
    socket.close();
  }

  navigate = (url, scale, defaultOutboundWeight,usingDefault,isWithOutweight) => {
    let outboundWeight;
    if(!isWithOutweight){
    this.state.scaleWeights.forEach((scale) => {
      if (scale.weightType === 'Outbound') {
        outboundWeight = scale.weight;
      }
    });
    window.localStorage.setItem(
      'weightData', 
      JSON.stringify({
        weightType: scale?.weightType ?? 'Outbound',
        weights: this.state.weights,
         weight: (usingDefault && !defaultOutboundWeight)
        ? 0
        : defaultOutboundWeight
          ? defaultOutboundWeight
          : outboundWeight ?? scale?.weight ?? 0,
        companyId: scale?.companyId,
        date: moment(),
        manuallyEnterWeight: scale?.manuallyEnterWeight ? scale?.weight : 0,
        usingDefault:usingDefault
      })
    );
    }
    this.props.history.push(url);
  };

   pending_payment = async () => {
    const {
      value: {
        data: { orders }
      }
    } = await this.props.fetchOrderList({
      from: null,
      to: null,
      type: "pending-payment",
      filterCustomers:[],
      filterDrivers:[],
      filterUsers:[],
      sortOrder:{updatedAt:-1},
      skip : 0,
      limit: 10,
      isPaymentLater: true,
      isFailedPayment : false,
    });
   this.setState({pending_payment_orders: orders, is_pending_payment_show: true})
  }

  render() {
    const {
      startDate,
      endDate,
      user,
    } = this.state;
    const { fetchOrders, history } = this.props;
    return (
      <div className='layout-has-sidebar'>
         <TopNavigation
          {...this.props}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
       
        <main className='dashboard-layout-content scale__container'>
          <div className='page-header'>
            <h2 className='heading-title'>Scale</h2>
          </div>
          <section className='inbound__Outbound-section'>
            <div className='list'>
              { this.state.scaleWeights && this.state.scaleWeights.map((scale, index) => {
                let weight;
                return (
                  <React.Fragment key={index}>
                    <div className='items'>
                      <h3 className='scale__title'>{scale.scaleName}</h3>
                      <div className='inbound__outbound-group'>
                        <button
                          className={
                            scale?.weightType === 'Inbound'
                              ? 'btn btn-inbound active'
                              : 'btn btn-inbound'
                          }
                          onClick={() => {
                              this.setWeightType('Inbound', scale._id);
                          }}>
                          Inbound
                        </button>
                        <button 
                          className={
                            scale?.weightType === 'Outbound'
                              ? 'btn btn-outbound active'
                              : 'btn btn-outbound'
                          }
                          onClick={() => {
                              this.setWeightType('Outbound', scale._id);
                          }}>
                          Outbound
                        </button>
                      </div>
                      <div className='outbound__weight'>
                        <div className='outbound__weight-body'>
                          <h3 className='title'>{scale.weightType} Weight</h3>
                          <p className='date__time'>
                            {moment().format('MM/DD/YY')} at{' '}
                            {moment().format('LT')}{' '}
                          </p>
                          {!scale.manuallyEnterWeight ? (
                            <p className='hours'>{scale.weight ?? 0}</p>
                          ) : (
                            <input
                              value={weight}
                              autoFocus
                              type='text'
                              maxLength={8}
                              onChange={(e) => {
                                this.manuallyEnterWeight(
                                  e.target.value,
                                  scale._id
                                );
                              }}
                              className='form-control material-textfield-input hours'
                              required
                            />
                          )}
                          <p className='sub__title'>lbs</p>
                        </div>
                        <div className='outbound__weight-footer'>
                          <OutboundweightIcon className='outbound__weight-bg' />
                          {scale.weightType === 'Inbound' && (
                            <span
                              disabled={scale.weight === 0}
                              className='btn btn-dark btn-lg w-100 assign__customer-btn'
                              onClick={() =>
                                this.navigate(
                                  {
                                    pathname: '/assign-inbound-weight',
                                  },
                                  scale
                                )
                              }>
                              Assign Customer
                            </span>
                          )}
                        </div>
                      </div>
                      {(_.get(user, 'role', false) === 'admin' ||
                        _.get(user, 'role', false) === 'supervisor') && (
                        <>
                        {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.manuallyEnterScaleWeight === true) ?
                        <div className='enter__weight-section'>
                          <p>OR</p>
                          <button
                            className='btn btn-dark btn-lg w-100 enter__weight-btn'
                            onClick={() =>
                              this.isManallyEnterWeight(true, scale._id)
                            }>
                            Manually Enter Weight
                          </button>
                        </div>:null}
                        </>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </section>
          {
           !this.state.is_pending_payment_show ?
            <PendingOrders
            startDate={startDate}
            endDate={endDate}
            weightType={this.state.weightType}
            fetchOrders={fetchOrders}
            history={history}
            navigate={(path, scale, defaultOutboundWeight,usingDefault,isWithOutweight) => {
              this.navigate(path, scale, defaultOutboundWeight,usingDefault,isWithOutweight);
            }}
            isPendingOutboundShow={true}
            pendingData={this.pending_payment}
            setPendingPaymentData={()=>this.setState({pending_payment_orders: [], is_pending_payment_show: false})}
            />
            :
            <>
              <div className="custome__tabs">
                <ul className="custome__tabs-list">
                  <li onClick={()=> this.setState({pending_payment_orders: [], is_pending_payment_show: false})}>
                    <span className="">Pending Outbound</span>
                  </li>
                  <li>
                    <span className="active">Pending Payments</span>
                  </li>
                </ul>
              </div>  
              <PendingPayments
               orders={this.state.pending_payment_orders}
               sortOrder={fetchOrders}
               history={history}
               setOrder={field => this.setOrder(field)}
               areArrowsRemove={true}
               />
               {
                this.state?.pending_payment_orders?.length > 0 &&
                 <div className="row">
                  <div className="col-lg-12 text-center">
                     <button className="btn btn-dark btn-sm mb-5" onClick={()=> this.navigate('/pending-payments')}>
                       View All Pending Payments
                     </button>
                   </div>
                 </div>
              }
            </>
          }
        </main>
      </div>
    );
  }
}
