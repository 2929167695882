import React, { PureComponent } from "react";
import "../styles.scss";
import { Dropdown, Menu } from "antd";
import {
  FilterIcon,
  MoreIcon,
  SyncIcon,
  DeliveryStatusIcon,
  CloseIcon,
  DownarrowIcon,
  StateUpArrowIcon,
} from "../../../components/icons";
import moment from "moment-timezone";
import { message } from "antd";
import { Drawer } from "antd";
import { Spin } from "antd";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import CustomerDetailComponent from "../../CustomerDetail/component";
// import filterIcon from "../../../images/filter_icon.svg";
import InvoicePDF from "./invoicePDF";
import InvoiceDetailComponent from "./invoiceDetail";
import PrintInvoiceModal from "./printInvoice";
import CryptoJS from "crypto-js";
import {PrintPaymentRecepit, bottomContent} from "../../../utils/printInvoiceReceipt";
import { paymentMethodPosted, reOrderArray, paymentMethod } from "../../../constants/common";
import EmailAlert from "../../../utils/email_warning_popup";
import { DebounceInput } from "react-debounce-input";
import SearchCheckboxComponent from "../../Common/SearchCheckbox";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class PostedComponent extends PureComponent {
  tableRef = React.createRef();
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      searchStartsWith: this.props.searchStartsWith,
      toggleMenu: false,
      sortOrder: {
        createdAt: -1, 
      },
      activeUrl: "",
      postedOrderData: [],
      searchText: this.props.searchText || "",
      startDate: moment(props.date.startDate)?.tz(tz)?.toDate(),
      endDate: moment(props.date.endDate)?.tz(tz)?.toDate(),
      companyData: {},
      companyDataWithIndex: [],
      isModelOpen: false,
      modelData: {},
      modelData2: {},
      visible: false,
      invoiceDeliveryData: [],
      filtersData: [],
      reRender: false,
      selectedCheckBoxData: [],
      spinning: false,
      invoicesTotal : 0,
      paymentsTotal: 0,
      currentPage: Number(sessionStorage.getItem("posted-pagination")) ? Number(sessionStorage.getItem("posted-pagination"))  : 1,
      totalItems: 0,
      limit: 20,
      skip: 0,
      isCustomerModelOpen: false,
      customerId: "",
      defaultUrl: "/billing/posted-transactions",
      isSyncing: false,
      selectedIndex: [],
      singleSync : [],
      syncPosted : false,
      totalOrdersAmout: 0,
      API_URL:process.env.REACT_APP_API_HOSTNAME,
      qbDetails: {},
      isQbData: false,
      userData:{},
      isLoading:true,
      optionDisable: true,
      showModal:false,
      isSyncLoaderIndex:[],
      tz,
      firstTimeRender: true,
      isSingleSyncing:false,
      isBulkSyncing:false
    };
  }
  closeModalAlert = () => {
    this.setState({ showModal: false });
  };

  componentDidMount = async () => {
    const searchText = this.props.searchText || "";
    // this.setState({ searchText });
    document.title = "Posted | Weighworks";
    const sessionFilterData = sessionStorage.getItem('posted_filtersData');
    if (sessionFilterData) {
      try {
        const filtersDataArray = JSON.parse(sessionFilterData);

        this.setState({
          filtersData: filtersDataArray,
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    // const searchText = "";
    this.fetchPostedOrderData(
      searchText,
      this.state.startDate,
      this.state.endDate
    );

    const {
      value: { user },
    } = await this.props.fetchMe();
    this.setState({
      userData:user,
      companyData: user.company,
      companyDataWithIndex: [user.company],
    });
    const limit = await localStorage.getItem("postedLimit")
    if(limit){
      this.setState({limit:  Number(limit)})
    }
    this.getQbDetails()
    this.fetchInvoiceDeliveryData();
    const urlarray = window.location.pathname.split("/");
    if (urlarray[4] === "general") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
    if (urlarray[5] === "payment-receipt") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
  };

  getQbDetails = async() => {
    const { value } = await this.props.getQbDetails()
    if(value?.status){
      this.setState({ isQbData: true, qbDetails: value.data })
    }
  }

  updateinvoiceDetailData = (data) => {
    const needReviedData = this.state.needReviedData;
    needReviedData[data.index] = data;
    this.setState({ needReviedData: needReviedData });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  closeModel = () => {
    this.setState({ isModelOpen: false, isPrintRecipt: false });
  };
  fetchInvoiceDeliveryData = async () => {
    const {
      value: { data },
    } = await this.props.fetchInvoiceDeliveryData();
    this.setState({ invoiceDeliveryData: data });
  };

  componentDidUpdate = async () => {
    let startDate = this.props.date.startDate;
    let endDate = this.props.date.endDate;
    if (this.state.startDate !== startDate || this.state.endDate !== endDate) {
      this.setState({ startDate: startDate, endDate: endDate });
      let searchText = "";
      !this.state?.firstTimeRender && this.fetchPostedOrderData(searchText, startDate, endDate);
      this.setState({firstTimeRender: false})
    }
  };

 afterBulkOptionUse=()=>{
  let postedOrderData=this.state.postedOrderData  
  this.setState({ selectedCheckBoxData: [] });
  postedOrderData.map((item, index) => {
    postedOrderData[index].checkBox = false;
    return null;
  });
  this.setState({
    postedOrderData: postedOrderData,
    reRender: !this.state.reRender,
    batchAction:""
  });
 }

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };
  reminderEmail = async (index) => {
    const postedOrderData = this.state.postedOrderData[index];
    const companyData = this.state.companyData;
    const billingEmail = postedOrderData?.customer?.secondaryBillingContacts ? 
    postedOrderData?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: postedOrderData?.customer?.customerName,
        customerId: postedOrderData?.customer?._id,
        chargeStripeCardFees : companyData?.chargeStripeCardFees,
      },
      customer: {
        email: postedOrderData?.customer.email,
        billingEmailAddress: [...billingEmail,postedOrderData?.customer?.billingEmailAddress],
        companyName: postedOrderData?.customer?.displayName,
      },
      invoice: {
        invoiceData: postedOrderData,
        invoiceId: postedOrderData?.invoiceId,
        dueDate: postedOrderData?.dueDate,
        total: postedOrderData?.total,
        balance:
          Number(postedOrderData?.total) - Number(postedOrderData.amountPaid),
        amountPaid: postedOrderData?.amountPaid,
      },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendReminderInvoiceEmail(payload);
    if (status) {
      message.success(showMessage);
    }
  };

  sendInvoice = async (index) => {
    const postedOrderData = this.state.postedOrderData[index];
    const companyData = this.state.companyData;
    const billingEmailAddress = this.state?.postedOrderData?.[index]?.customer?.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress?.trim() === "") {
      this.setState({ showModal: true})
    } 
    else{
    const billingEmail = postedOrderData?.customer?.secondaryBillingContacts ? 
    postedOrderData?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: postedOrderData?.customer?.customerName,
        customerId: postedOrderData?.customer?._id,
        chargeStripeCardFees: companyData?.chargeStripeCardFees,
      },
      customer: {
        email: postedOrderData?.customer.email,
        billingEmailAddress: [...billingEmail,postedOrderData?.customer?.billingEmailAddress],
        companyName: postedOrderData?.customer?.displayName,
      },
      invoice: {
        invoiceData: postedOrderData,
        invoiceId: postedOrderData?.invoiceId,
        dueDate: postedOrderData?.dueDate,
        total: postedOrderData?.total,
        balance:
          Number(postedOrderData?.total) - Number(postedOrderData.amountPaid),
        amountPaid: postedOrderData?.amountPaid,
        paymentTerms: postedOrderData?.paymentTerms,
        paymentType:postedOrderData?.customer?.paymentType
      },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail(payload);
    if (status) {
      const postedOrderData = this.state.postedOrderData;
      postedOrderData[index].deliveryStatus = true;
      this.setState({
        postedOrderData: postedOrderData,
        reRender: !this.state.reRender,
      });
      message.success(showMessage);
    }}
  };
  sendInvoice2 = async (index) => {
    const payloadforFetchData = {
      invoiceId: this.state.postedOrderData[index].invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payloadforFetchData);
    const newdata = data.paymentInvoices[0];
    const postedOrderData = this.state.postedOrderData[index];
    const companyData = this.state.companyData;
    const billingEmailAddress = this.state?.postedOrderData?.[index]?.customer?.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress?.trim() === "") {
      this.setState({ showModal: true})
    } 
    else{
    const billingEmail = postedOrderData?.customer?.secondaryBillingContacts ? 
    postedOrderData?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: postedOrderData?.customer?.customerName,
        customerId: postedOrderData?.customer?._id,
        chargeStripeCardFees: companyData?.chargeStripeCardFees,
      },
      customer: {
        address: postedOrderData?.customer.address,
        phone: postedOrderData?.customer.phone,
        city: postedOrderData?.customer.city,
        zip: postedOrderData?.customer.zip,
        state: postedOrderData?.customer.state,
        email: postedOrderData?.customer.email,
        billingEmailAddress: [postedOrderData?.customer?.billingEmailAddress,...billingEmail],
        companyName: postedOrderData?.customer?.displayName,
      },
      invoice: {
        invoiceId: postedOrderData?.invoiceId,
        dueDate: postedOrderData?.dueDate,
        total: postedOrderData?.total,
        invoiceData: postedOrderData,
        balance:
          Number(postedOrderData?.total) - Number(postedOrderData.amountPaid),
        amountPaid: postedOrderData?.amountPaid,
        paymentType:postedOrderData?.customer?.paymentType,
        paymentTerms: postedOrderData?.paymentTerms,
      },
      Data: { ...newdata, paymentMethodType:paymentMethod(newdata) },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail2(payload);
    if (status) {
      const postedOrderData = this.state.postedOrderData;
      postedOrderData[index].deliveryStatus = true;
      this.setState({
        postedOrderData: postedOrderData,
        reRender: !this.state.reRender,
      });
      message.success(showMessage);
    }}
  };
  handleSortClick = (key) => {
    this.setState(
      (prevState) => {
        const newSortOrder = prevState.sortOrder[key] === 1 ? -1 : 1;
        const updatedSortOrder = {
          [key]: newSortOrder, 
        };
  
        return {
          sortOrder: updatedSortOrder,
          currentPage: 1,
          skip: 0, 
        };
      },
      () => {
        const { sortOrder, searchText, startDate, endDate, limit } = this.state;
        this.fetchPostedOrderData(searchText, startDate, endDate, limit, sortOrder);
      }
    );
  }; 
  fetchPostedOrderData = async (searchText, startDate, endDate) => {
    // this.setState({ isLoading: true });
    let postedOrderData = [];
    const { skip, limit } = this.state;
    const billingDate = JSON.parse(localStorage.getItem('BillingDate')) || {
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    };
    const filtersData = JSON.parse(sessionStorage.getItem('posted_filtersData')) || this.state.filtersData
    const payload = {
      searchText: searchText,
      // startDate: moment(startDate)?.startOf("day")?.toISOString(),
      // endDate: moment(endDate)?.endOf("day")?.toISOString(),
      startDate: moment(billingDate.startDate)?.startOf("day")?.toISOString(),
      endDate: moment(billingDate.endDate)?.endOf("day")?.toISOString(),
      limit: limit,
      skip: skip,
      filterList: filtersData,
      searchStartsWith: this.state.searchStartsWith,
      sortOrder: JSON.stringify(this.state.sortOrder),
    };
    const {   
      value: { data },
    } = await this.props.fetchPostedOrder(payload);  

    const {
       value: {
        data: {
          paymentTotal,
          invoiceTotal
        }
      }
    }= await this.props.fetchPostedInvoicesCount({...payload, tabtype: "posted"})
    this.setState({
      invoicesTotal: invoiceTotal,
      paymentsTotal : paymentTotal
    })
    // this.setState({invoicesTotal : data?.invoiceTotal , paymentsTotal : data?.paymentTotal})
    if(this.state.companyData){
      const {
        value: {status, data },
      } = await this.props.fetchSetting( this.state.companyData.sisterCompany);
      if(status && data){
      this.setState({sisterCompanyData:data,isSisterCompany:data?.sisterCompany?.length > 0 ? true : false})
      }
    }

    for (let i = 0; i < data.orders.length; i++) {
      let item = data.orders[i];
      let totalOrdersAmount = 0;
    
      for (let j = 0; j < item.order.length; j++) {
        let orderItem = item.order[j];
        totalOrdersAmount += orderItem ? orderItem.totalAmount : 0;
      }
    
      postedOrderData.push({
        ...item,
        checkBox: false,
        customAttachments: [],
        showAttachments: [],
        totalOrdersAmount: totalOrdersAmount,
      });
    }


    // data.orders.map((item) => {
    //   let totalOrdersAmount = 0;
    //   item.order.map((item) => {
    //     totalOrdersAmount = totalOrdersAmount + item?.totalAmount;
    //     return null;
    //   });
    //   postedOrderData.push({
    //     ...item,
    //     checkBox: false,
    //     customAttachments: [],
    //     showAttachments: [],
    //     totalOrdersAmount: totalOrdersAmount,
    //   });
    //   return null;
    // });
    if (this.tableRef.current) {
      this.tableRef.current.scrollTop = 0;
    }
    //     const path = window.location.pathname.split("/")
    //   if(path[1]==="posted"){
    //  this.setState({isCustomerModelOpen:true,customerId:data?.orders[0]?.customer?.customerId})
    //   }
    this.setState({
      postedOrderData: postedOrderData,
      totalItems: data.totalCount,
      isLoading:false
    });
  };

  modelOpen = (index) => {
    let postedOrderData = this.state.postedOrderData[index];
    postedOrderData.index = index;
    postedOrderData.company = this.state.companyDataWithIndex;
    this.setState({ isModelOpen: true, modelData: postedOrderData });
  };
  modelOpenNew = async (index) => {
    let postedOrderData = this.state.postedOrderData[index];
    postedOrderData.index = index;
    postedOrderData.company = this.state.companyDataWithIndex;
    const payload = {
      invoiceId: postedOrderData?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    this.setState({
      isPrintReciptt: true,
      modelData: postedOrderData,
      modelData2: newdata,
    });
  };
  modelOpenPrint = async (index) => {
    let postedOrderData = this.state.postedOrderData[index];
    postedOrderData.index = index;
    postedOrderData.company = this.state.companyDataWithIndex;
    const payload = {
      invoiceId: postedOrderData?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    if (newdata) {
      this.setState({
        isPrintReciptposted: true,
        modelData: postedOrderData,
        modelData2: newdata,
      });
    }
  };
  closePrintRecipt = () => {
    if (this.props.printByDropDown) {
      this.setState({ isPrintRecipt: false });
      this.setState({ isPrintReciptt: false });
      this.setState({ isPrintReciptposted: false });
      this.props.closeModel();
    } else {
      this.setState({ isPrintRecipt: false });
      this.setState({ isPrintReciptt: false });
      this.setState({ isPrintReciptposted: false });
    }
  };
  openFilterList = async (value) => {
    this.setState({ currentPage: 1,skip: 0 , isLoading : true})
    const filtersData = this.state.filtersData;
    const submenu = ["cash", "check", "credit-card", "credit-memo", "wire","terminal"];
    const paymentCheckbox = "payment";
    if (value === paymentCheckbox) {
      if (!filtersData.includes(value)) {
        filtersData.push(value);
        filtersData.push(...submenu);
      } else {
        let index = filtersData.indexOf(value);
        if (index !== -1) {
          filtersData.splice(index, 1);
        }
        submenu.forEach(submenuItem => {
          var submenuIndex = filtersData.indexOf(submenuItem);
          if (submenuIndex !== -1) {
            filtersData.splice(submenuIndex, 1);
          }
        });
      }
    } else {
      if (filtersData.includes(value)) {
        let index = filtersData.indexOf(value);
        if (index !== -1) {
          filtersData.splice(index, 1);
        }
        else {
          filtersData.push(value);
        }
        const submenuSelected = submenu.some(item => filtersData.includes(item));
        if (!submenuSelected && filtersData.includes(paymentCheckbox)) {
          var paymentIndex = filtersData.indexOf(paymentCheckbox);
          filtersData.splice(paymentIndex, 1);
        }
      } else {
        filtersData.push(value);
        if (!filtersData.includes(paymentCheckbox) && submenu.includes(value)) {
          filtersData.push(paymentCheckbox);
        }
        
       }
      this.setState(
        { currentPage: 1, skip: 0, selectedCheckBoxData: [] },
        () => {
          this.applyFiltersAndFetchData();
        }
      );
    }
  
    this.setState(
      { filtersData: filtersData, reRender: !this.state.reRender },
      () => {
        sessionStorage.setItem('posted_filtersData', JSON.stringify(this.state.filtersData))
        this.fetchPostedOrderData(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  numberWithCommas = (x) => {
    x=parseFloat(x)
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  applyFiltersAndFetchData = () => {
    const { searchText, startDate, endDate } = this.state;

    this.setState(
      { reRender: !this.state.reRender },
      () => {
        sessionStorage.setItem('posted_filtersData', JSON.stringify(this.state.filtersData));
        this.fetchPostedOrderData(searchText, startDate, endDate);
      }
    );
};

onChangePage = async (page) => {
    this.setState(
      { currentPage: page, skip: (page - 1) * this.state.limit, selectedCheckBoxData: [] },
      () => {
        this.applyFiltersAndFetchData();
      }
    );
    await sessionStorage.setItem("posted-pagination", page);
};


  closeCustomerDetatilModel = () => {
    this.setState({ isCustomerModelOpen: false });
    this.props.navigate("/billing/posted-transactions");
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  assignUrl = (url) => {
    // const { searchText } = this.state;
    let customerId = "";
    const path = url.split("/");
    if (path[1] !== "order-details" && this.state.customerId === "") {
      customerId = path[2];
    } else {
      customerId = this.state.customerId;
    }

    if (path[1] === "order-details" || path[1] === "edit-customer") {
      if (path[1] === "order-details") {
        this.props.navigate(url);
        this.setState({ isCustomerModelOpen: false });
      }
      if (path[1] === "edit-customer") {
        this.props.navigate({
          pathname: "/edit-customer",
          state: {
            id: customerId,
          },
        });
      }
    } else {
      if (path[3] === "general") {
        this.props.navigate(`/posted/customer-detail/${customerId}/general`);
      }
      if (path[3] === "billing" && path[4] === "invoice") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/billing/invoice`,
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "payment-receipt") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/billing/payment-receipt`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "projects") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/billing/projects`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "credit-memo") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/billing/credit-memo`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "projects") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/projects`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "past-weigh-ins") {
        this.props.navigate(
          `/posted/customer-detail/${customerId}/past-weigh-ins`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "rates") {
        this.props.navigate(`/posted/customer-detail/${customerId}/rates`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "drivers") {
        this.props.navigate(`/posted/customer-detail/${customerId}/drivers`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "trucks") {
        this.props.navigate(`/posted/customer-detail/${customerId}/trucks`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "payment-methods") {
        this.props.navigate(`/posted/customer-detail/${customerId}/payment-methods`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "history") {
        this.props.navigate(`/posted/customer-detail/${customerId}/history`);
        this.setState({ isCustomerModelOpen: true });
      }
    }
  };

  menu = (index) => {
    const invoiceType = this.state.postedOrderData[index]?.invoiceType;
    const paymentTerms = this.state.postedOrderData[index]?.paymentTerms;
    // const invoiceId = this.state.postedOrderData[index]?.invoiceId;
    const balance = this.state.postedOrderData[index]?.balacnce;
    return (
      <>
      <Menu>
        {invoiceType === "payment" &&
        !this.state.postedOrderData[index]?.isRecivePaymentInvoice ? (
          <>
            <Menu.Item
              key="0"
              onClick={() => {
                this.modelOpen(index);
              }}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.sendInvoice(index);
              }}
              key="1"
            >
              Send
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.openBillingReciept(this.state.postedOrderData[index]);
                // this.props.navigate(`/billing-receipt/${this.state.postedOrderData[index].invoiceId}`)
                // this.setState({ isPrintRecipt: true }, () => {
                //   this.modelOpen(index);
                // });
              }}
              key="2"
            >
              Print
            </Menu.Item>
            {this.state.postedOrderData[index].isRecivePaymentInvoice &&
            this.state.postedOrderData[index].qbInvoiceId &&
            !this.state.postedOrderData[index].isSynced ? (
              <Menu.Item
                key="4"
                onClick={() => {
                  this.syncPostedOrder(index);
                }}
              >
                Sync to Quickbooks
              </Menu.Item>
            ) : (
              this.state.postedOrderData[index].balacnce > 0 &&
              !this.state.postedOrderData[index].isRecivePaymentInvoice && (
                <Menu.Item
                  key="3"
                  onClick={() => {
                    this.syncPostedOrder(index);
                  }}
                >
                  Sync to Quickbooks
                </Menu.Item>
              )
            )}
            <Menu.Item
              key="4"
              onClick={() => {
                this.lockPostedOrder(index);
              }}
            >
              Lock
            </Menu.Item>
          </>
        ) : invoiceType === "payment" &&
          this.state.postedOrderData[index]?.isRecivePaymentInvoicesId ? (
          <>
            <Menu.Item
              key="0"
              onClick={() => {
                // this.modelOpenNew(index);
                this.openBillingRecieptForPR(index, true);
              }}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.sendInvoice2(index);
              }}
              key="1"
            >
              Send
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.openBillingRecieptForPR(index);
              }}
              key="2"
            >
              Print
            </Menu.Item>
            {this.state.postedOrderData[index].isRecivePaymentInvoice &&
            this.state.postedOrderData[index].qbInvoiceId &&
            !this.state.postedOrderData[index].isSynced ? (
              <Menu.Item
                key="3"
                onClick={() => {
                  this.syncPostedOrder(index);
                }}
              >
                Sync to Quickbooks
              </Menu.Item>
            ) : (
              this.state.postedOrderData[index].balacnce > 0 &&
              !this.state.postedOrderData[index].isRecivePaymentInvoice && (
                <Menu.Item
                  key="4"
                  onClick={() => {
                    this.syncPostedOrder(index);
                  }}
                >
                  Sync to Quickbooks
                </Menu.Item>
              )
            )}
            <Menu.Item
              key="5"
              onClick={() => {
                this.lockPostedOrder(index);
              }}
            >
              Lock
            </Menu.Item>
            {/* <Menu.Item
              key="6"
              onClick={() => {
                this.editPaymentReceipts(index);
              }}
            >
              Edit
            </Menu.Item> */}
            {/* <Menu.Item key="6">
        Needs Review 
        </Menu.Item> */}
          </>
        ) : (
          <>
            <Menu.Item
              key="0"
              onClick={() => {
                this.modelOpen(index);
              }}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.sendInvoice(index);
              }}
              key="1"
            >
              Send
            </Menu.Item>
            {invoiceType === "invoice" && paymentTerms === "account" && 
            <Menu.Item
              onClick={() => {
                this.reminderEmail(index);
              }}
              key="2"
            >
              Send Reminder
            </Menu.Item>}
            {invoiceType === "invoice" && paymentTerms === "account" &&  <Menu.Item
              onClick={() => {
                this.linkToPayment(index);
              }}
              key="9"
            >
              Payment Link
            </Menu.Item>}
            <Menu.Item
              onClick={() => {
                // this.setState({ isPrintRecipt: true }, () => {
                //   this.modelOpen(index);
                // });
                this.openBillingReciept(this.state.postedOrderData[index]);
                // this.props.navigate(`/billing-receipt/${this.state.postedOrderData[index].invoiceId}`)
              }}
              key="3"
            >
              Print
            </Menu.Item>
            {invoiceType === "invoice" && paymentTerms === "account" &&
            <Menu.Item
              onClick={() => {
                this.props.navigate(
                  `/posted/receive-payment/${this.state.postedOrderData[index].customerId}`
                );
              }}
              key="4"
            >
              Receive Payment
            </Menu.Item>}
            {this.state.postedOrderData[index].isRecivePaymentInvoice &&
            this.state.postedOrderData[index].qbInvoiceId &&
            !this.state.postedOrderData[index].isSynced ? (
              <Menu.Item
                key="5"
                onClick={() => {
                  this.syncPostedOrder(index);
                }}
              >
                Sync to Quickbooks
              </Menu.Item>
            ) : (
              this.state.postedOrderData[index].balacnce > 0 &&
              !this.state.postedOrderData[index].isRecivePaymentInvoice && (
                <Menu.Item
                  key="6"
                  onClick={() => {
                    this.syncPostedOrder(index);
                  }}
                >
                  Sync to Quickbooks
                </Menu.Item>
              )
            )}
            <Menu.Item
              key="7"
              onClick={() => {
                this.lockPostedOrder(index);
              }}
            >
              Lock
            </Menu.Item>
            {balance  > 0 && !this.state.postedOrderData[index].isRecivePaymentInvoice && !this.state.postedOrderData[index].qbInvoiceId && (this.state.postedOrderData[index].amountPaid === null || this.state.postedOrderData[index].amountPaid === 0) && !this.state.postedOrderData[index].amountPaid && (
              <Menu.Item
                key="8"
                onClick={() => {
                  this.backToNeedReview(index);
                }}
              >
                Needs Review
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
      {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
      </>
    );
  };

  selectedCheckBox = (data, type, index) => {
    const selectedIndex = [...this.state.selectedIndex];
    const selectedIndexPos = selectedIndex.indexOf(index);
    if (selectedIndexPos > -1) {
      selectedIndex.splice(selectedIndexPos, 1);
    } else {
      selectedIndex.push(index);
    }
    this.setState((prevState) => ({ 
      selectedIndex : selectedIndex , 
      singleSync: prevState.singleSync.filter(i => i !== index),}));
    const { selectedCheckBoxData, postedOrderData } = this.state;
    if (type === "all") {
      let allIndex = postedOrderData.map((item, index) => index);
      allIndex = allIndex.filter(index => 
        !(this.state.selectedIndex.includes(index) ));
      this.setState({selectedIndex : allIndex , 
      })
      if (
        this.state.postedOrderData.length ===
        this.state.selectedCheckBoxData.length
      ) {
        this.setState({ selectedCheckBoxData: [] });
        postedOrderData.map((item, index) => {
          postedOrderData[index].checkBox = false;
          return null;
        });
        this.setState({
          postedOrderData: postedOrderData,
          reRender: !this.state.reRender,
        });
      } else {
        postedOrderData.map((item, index) => {
          postedOrderData[index].checkBox = true;
          if (!selectedCheckBoxData.includes(item._id))
            selectedCheckBoxData.push(item._id);

          return null;
        });
        this.setState({
          postedOrderData: postedOrderData,
          selectedCheckBoxData: selectedCheckBoxData,
          reRender: !this.state.reRender,
        });
      }
      if(postedOrderData.some(e => (e.paymentTerms !== "account" || e.balacnce === 0 || e.isRecivePaymentInvoice === true || e.qbInvoiceId || (e.amountPaid !== null && e.amountPaid !== 0)) && e.checkBox === true)) {
        this.setState({
          optionDisable: true
        })
    }
    else{
        this.setState({
          optionDisable: false
     })
    }
    } else {
      const arrayIndex = selectedCheckBoxData.indexOf(data._id);
      if (selectedCheckBoxData.includes(data._id)) {
        postedOrderData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.splice(arrayIndex, 1);
      } else {
        postedOrderData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.push(data._id);
      }
      this.setState({
        postedOrderData: postedOrderData,
        selectedCheckBoxData: selectedCheckBoxData,
        reRender: !this.state.reRender,
      });
    if(postedOrderData.some(e => (e.paymentTerms !== "account" || e.balacnce === 0 || e.isRecivePaymentInvoice === true || e.qbInvoiceId || (e.amountPaid !== null && e.amountPaid !== 0)) && e.checkBox === true)){
        this.setState({
           optionDisable: true
        })
    }
    else{
      this.setState({
        optionDisable: false
     })
    }
   }
  };

  reArrangOrdersData = (orderData) => {
    let order = orderData;
    let reArrangOrders = [];
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if (item?.orderBreakDownItems?.isminimumPrice) {
            let obj = {
              fixedItems: [
                {
                  fixedItem: "Dumping services",
                  unitPrice: item?.orderBreakDownItems?.totalAmountDue,
                  units: 1,
                  totalamout: item?.orderBreakDownItems?.totalAmountDue,
                },
              ],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType,
            };
            reArrangOrders.push(obj);
            return null;
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = _.startCase(fixedItems?.fixedItem);
                  if (fixedItemName.includes("Yard")) {
                    if (
                      Number(fixedItems?.unitPrice) > Number(item?.orderBreakDownItems?.capoutPrice)
                    ) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout:
                          item?.orderBreakDownItems?.capoutPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }else{
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: fixedItems?.unitPrice,
                        units: fixedItems.units,
                        totalamout:
                        fixedItems?.unitPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightFixedItems) => {
                    let chargeByWeightItemObject = {
                      fixedItem: chargeByWeightFixedItems?.fixedItem,
                      unitPrice: chargeByWeightFixedItems?.unitPrice,
                      units: chargeByWeightFixedItems?.units,
                      totalamout:
                        chargeByWeightFixedItems?.unitPrice *
                        chargeByWeightFixedItems?.units,
                    };

                    obj.fixedItems.push(chargeByWeightItemObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightItems) => {
                    let chargeByWeightItemsObject = {
                      fixedItem: chargeByWeightItems?.fixedItem,
                      unitPrice: chargeByWeightItems?.unitPrice,
                      units: chargeByWeightItems?.units,
                      totalamout:
                        chargeByWeightItems?.unitPrice *
                        chargeByWeightItems?.units,
                    };
                    obj.fixedItems.push(chargeByWeightItemsObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          let total = 0
          let dumpRate=0
         let isCapOutPrice=(item?.pricingTierCapoutPrice > 0)
         let units = 0
         if (!item?.isMinimumPrice) {
           if (item?.dumpRateType.indexOf("Yard") !== -1) {
             units = Number(item?.yardage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate= Number(item.pricingTierCapoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                total = Number(item?.yardage) * Number(item?.dumpRate)
               }
             } else{
               dumpRate=Number(item?.dumpRate)
                total = Number(item?.yardage) * Number(item?.dumpRate)
             }
           } else {
             units = Number(item?.tonnage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate=Number(item?.capoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                 total = Number(item?.tonnage) * Number(item?.dumpRate)
               }
             } else{
               dumpRate=Number(item?.dumpRate)
               total = Number(item?.tonnage) * Number(item?.dumpRate)
             }
           }
           let obj = {
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(dumpRate),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             paymentType:item?.paymentType,
             stripeCardFees:item?.stripeCardFees,
             totalAmount: total,
             units: units,
             dumpRateType: item?.dumpRateType,
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             createdAt: item?.createdAt,
           };
           reArrangOrders.push(obj);
         } else {
           let obj = {
             isMinimumPrice: item?.isMinimumPrice,
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(item?.totalAmount),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             totalAmount: item?.totalAmount,
             units: 1,
             dumpRateType: "Dumping services",
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             createdAt: item?.createdAt,
           };
           reArrangOrders.push(obj);
         }
        }
        return null;
      });
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders;
  };

  bulkOptions = async (event) => {
    this.setState({ batchAction: event.target.value });
    const emailPromise = [];
    const postedOrderData = this.state.postedOrderData;
    const companyData = this.state.companyData;
    if (event.target.value === "send") {
      this.setState({ spinning: true });
      const filtered_data = postedOrderData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
      let newdata;
      postedOrderData.map(async(item) => {
        if (item.checkBox) {
          if(item.isRecivePaymentInvoice){
            const payload = {
              invoiceId: item?.invoiceId,
            };
            const {
              value: { data },
            } = await this.props.getReceivePaymentForPdf(payload);
            newdata = data.paymentInvoices[0];
          }
          if (filtered_data?.length <= 0) 
           {  this.setState({ showModal: true });
        } 
          else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
          item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
          :[]
          const payload = {
            company: {
              logo: companyData?.logo,
              businessInfo: companyData?.businessInfo,
              email: companyData?.email,
              domain: companyData?.domain,
              companyId: companyData?._id,
              companyName: companyData?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
              chargeStripeCardFees: companyData?.chargeStripeCardFees,
            },
            customer: {
             email :item.customer.email,
             city : item.customer.city,
             state :item.customer.city,
             phone: item.customer.phone,
             zip :item.customer.zip,
             billingEmailAddress :[item?.customer?.billingEmailAddress,...billingEmail],
             companyName:item?.customer?.displayName,
             customerName : item?.customer?.customerName,
             address : item?.customer?.address,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              total: item?.total,
              balance: item?.total - item?.amountPaid,
              amountPaid: item?.amountPaid,
            },
            Data: { ...newdata, paymentMethodType:paymentMethod(newdata) },
          };
          if(item.isRecivePaymentInvoice){
            emailPromise.push(this.props.sendInvoiceEmail2(payload));
          }
          else{
            emailPromise.push(this.props.sendInvoiceEmail(payload));
          }
          // const {
          //   value: { status },
          // } = this.props.sendInvoiceEmail(payload);
          // if (status) {
          //   this.setState({ bulkInvoiceEmailPleaseWait: false })
          // }
        }
        return null;
      } });
      const sendEmailResponce = await Promise.all(emailPromise);
      if (sendEmailResponce) {
        this.setState({ spinning: false,selectedCheckBoxData: [] });
        this.afterBulkOptionUse()
      }
    } else if (event.target.value === "print") {
      this.setState({ spinning: true });
      let printData = [];
      //  = postedOrderData.filter((item) => item.checkBox === true);
      for(const item of postedOrderData){
        if (item.checkBox === true) {
          printData.push({ ...item, company: [this.state.companyData]});
        }
      }
      const orderedArray = reOrderArray(printData);
      const {value} = await this.props.batchPrintInvoice({
        invoicelist: orderedArray,
      });
      if (value) {
        const url = URL.createObjectURL(value);
        const printWindow = window.open(url, '_blank');
          printWindow.onload = function() {
            printWindow.print();
          };
        
        this.setState({
          postedOrderData: postedOrderData,
          reRender: !this.state.reRender,
          selectedCheckBoxData: [],
          spinning: false,
        });
        this.afterBulkOptionUse()
      }
      /* const printPromises = printData.map((items, ind) => {
        return new Promise(async (resolve, reject) => {
            let { order} = items;
            const Company = this.state.companyData;
            const ordersData = this.reArrangOrdersData(sort_by_descending(order, "createdAt"))
            totalLength+=ordersData.length;
             items.company= [Company]
             let body1 = '';
             if(items.isRecivePaymentInvoice){
              const payload = {
                invoiceId: items?.invoiceId,
              };
              const {
                value: { data },
              } = await this.props.getReceivePaymentForPdf(payload);
              invoice.push(data.paymentInvoices[0])
              currentLength+=1;
              const invoices = invoice.filter(e => e.invoiceId === items.invoiceId )
              const newdata = data.paymentInvoices[0];
              const company = items.company[0];
              const isLastPromise = currentLength === totalLength
              body1 += PrintPaymentRecepit(items,newdata,company,invoices,isLastPromise)
             }
             else{
              currentLength+=1;
              if(this.state.isSisterCompany){
                body1 += PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData)
              }else{
                body1 += PrintInvoiceReceipt(items)
              }
             }

             for (let i = 0; i < ordersData.length; i++) {
               let ordr = ordersData[i];
               const { value } = await this.props.fetchOrder(ordr.ticketNumber);
               order = value.data;
               currentLength+=1; 
               const isLastPromise = currentLength === totalLength
               const last = ordersData.length-1 === i && isLastPromise
               if(this.state.isSisterCompany){
                body1 += PrintSisterCompanyOrderReceipt(value,last)
               }else{
                 body1 += PrintOrderReceipt(value,last)
               }
                }
            printWindow += body1;
            resolve()
        });
      }); */
   /*  if(this.state.isSisterCompany){
      printWindow += bottomSisterCompanyContent();
    }else{
      printWindow += bottomContent();
    }
      Promise.all(printPromises).then(() => {
        postedOrderData.forEach((item) => {
          item.checkBox = false;
        });
      }).finally(()=>{
        let printInvoice = window.open(`invoice`, "_blank");
        if(printInvoice){
        printInvoice.document.write(printWindow);
        printInvoice.document.close();
        printInvoice.onload = function () {
          printInvoice.print();
        };
      }
    })
      this.setState({
        postedOrderData: postedOrderData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      this.afterBulkOptionUse() */
    } else if (event.target.value === "download") {
      this.setState({ spinning: true });
      let printData = [];
      //  = postedOrderData.filter((item) => item.checkBox === true);
      for(const item of postedOrderData){
        if (item.checkBox === true) {
          printData.push({ ...item, company: [this.state.companyData]});
        }
      }
      const {
        value: { data },
      } = await this.props.downloadBulkInvoice({
        invoicelist: printData,
      });
      postedOrderData.map((item, index) => {
        postedOrderData[index].checkBox = false;
        return null;
      });
      this.setState({
        postedOrderData: postedOrderData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      window.open(data);
      this.afterBulkOptionUse()
    } else if (event.target.value === "syncwithqb") {
      this.setState({ spinning: true });
      const syncData = postedOrderData.filter((item) => item.checkBox === true);
      const indexData = [];
      for (const item of syncData) {
        indexData.push(item.invoiceId);
      }
      this.setState({
        isSyncing: true,
        isBulkSyncing :true,
        selectedIndex: [...this.state.selectedIndex, ...indexData],
      });
      const payload = {
        order: syncData,
      };
      await this.props.syncBulkPostedOrder(payload);
      this.fetchPostedOrderData(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
      );
      this.setState({
        isSyncing: false,
        isBulkSyncing:false,
        selectedIndex: [],
      });
      this.setState({ spinning: false });
      this.afterBulkOptionUse()
    } else if (event.target.value === "reminderInvoice") {
      this.setState({ spinning: true });
      const filtered_data = postedOrderData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
      postedOrderData.map((item) => {
        if (item.checkBox && !item?.isRecivePaymentInvoice) {
          this.setState({ bulkInvoiceEmailPleaseWait: true });
          if (filtered_data?.length <= 0) 
          {  this.setState({ showModal: true });
       } 
    else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
          item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
          :[]
          const payload = {
            company: {
              logo: companyData?.logo,
              businessInfo: companyData?.businessInfo,
              email: companyData?.email,
              domain: companyData?.domain,
              companyId: companyData?._id,
              companyName: companyData?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
            },
            customer: {
              email: item?.customer.email,
              billingEmailAddress: [...billingEmail,item?.customer?.billingEmailAddress],
              companyName: item?.customer?.displayName,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              total: item?.total,
              balance: item?.total - item?.amountPaid,
              amountPaid: item?.amountPaid,
            },
          };
          emailPromise.push(this.props.sendReminderInvoiceEmail(payload));
          // const {
          //   value: { status },
          // } = this.props.sendInvoiceEmail(payload);
          // if (status) {
          //   this.setState({ bulkInvoiceEmailPleaseWait: false })
          // }
        }}
        return null;
      });
      const sendEmailResponce = await Promise.all(emailPromise);
      if (sendEmailResponce) {
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
      }
    } else if(event.target.value === "lock"){
      this.setState({ spinning: true });
      const lockData = postedOrderData.filter(
        (item) => item.checkBox === true
      );
      const {
        value: { status },
      } = await this.props.movedInvoiceBulkAction({
        data:lockData, updateType: "finalized", billingStatus:"finalized"
      });
      if(status){
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
        const searchText = "";
        this.fetchPostedOrderData(
          searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    } else if(event.target.value === "needReview"){
      this.setState({ spinning: true });
      const needReviewData = postedOrderData.filter(
        (item) => item.checkBox === true && item.paymentTerms === "account" && !item.isRecivePaymentInvoice && item.balacnce > 0 && (item.amountPaid === 0 || item.amountPaid === null)
      );
      const {
        value: { status },
      } = await this.props.movedInvoiceBulkAction({
        data:needReviewData, updateType: "needReview", billingStatus:"needReview"
      });
      if(status){
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
        const searchText = "";
        this.fetchPostedOrderData(
          searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    }
  };

  openBillingReciept = async (data) => {
    Object.assign(data, { company: this.state.companyData });
    const orderedArray = reOrderArray([data]);
    const {value} = await this.props.batchPrintInvoice({
      invoicelist: orderedArray,
    });
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        };
      }
   /*  let printWindow = "";
    let printData = [data];
    let body1 = "";
    if(this.state.isSisterCompany){
      body1 += bottomSisterCompanyContent();
    }else{
      body1 += bottomContent();
    }
    const printPromises = printData.map((items, i) => {
      return new Promise(async (resolve, reject) => {
        let { order} = items;
        const Company = this.state.companyData;
        const ordersData = sort_by_descending(this.reArrangOrdersData(order), "createdAt");
        items.company=[Company]
        if(this.state.isSisterCompany){
          body1 += PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData)
        }else{
          body1 += PrintInvoiceReceipt(items)
        }
        for (let i = 0; i < ordersData.length; i++) {
          const last = ordersData.length-1 === i
          let ordr = ordersData[i];
          const { value } = await this.props.fetchOrder(ordr.ticketNumber);
          order = value.data;
          if(this.state.isSisterCompany){
            body1 +=PrintSisterCompanyOrderReceipt(value,last)
          }else{
            body1 +=PrintOrderReceipt(value,last)
          }
        }
        printWindow += body1;
        resolve()
      });
    });

    Promise.all(printPromises).then(() => {}).finally(()=>{
      let printInvoice = window.open(`invoice`, "_blank");
      if(printInvoice){
      printInvoice.document.write(printWindow);
      printInvoice.document.close(); 
      printInvoice.onload = function () {
        printInvoice.print();
      };
    }
  }) */
  };

  openBillingRecieptForPR = async (index, isView=false) => {
    let printWindow = "";
    let postedOrderData = this.state.postedOrderData[index];
    postedOrderData.index = index;
    postedOrderData.company = this.state.companyDataWithIndex;
    const payload = {
      invoiceId: postedOrderData?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data?.paymentInvoices?.[0];
    const company = data?.company ? data?.company : postedOrderData?.company?.[0] ? postedOrderData?.company?.[0] : this.state.companyDataWithIndex;
    Object.assign(company, { sisterCompanydetails: this.state.isSisterCompany ? this.state.sisterCompanyData :undefined });
    const invoices = newdata && newdata?.invoices;
    let body1 = ""
      body1 += bottomContent();
        const printPromises = [postedOrderData].map((items, i) => {
      return new Promise((resolve, reject) => {
        body1 += PrintPaymentRecepit(items,newdata,company,invoices,true,isView)
        printWindow += body1;
        resolve()
      });
    });
      if (!isView) {
        const {value} = await this.props.printPaymentReciept({html:printWindow,ticketNumber:postedOrderData.invoiceId})
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        };
      }
      } else {
        Promise.all(printPromises).then(() => {}).finally(()=>{
          let printInvoice = window.open(`invoice`, "_blank");
          if(printInvoice){
          printInvoice.document.write(printWindow);
          printInvoice.document.close();
          printInvoice.onload = function () {
            if(!isView){
              printInvoice.print();
            }
          };
        }
      }) 
      }
  };

  syncPostedOrder = async (index) => {
    this.setState({selectedCheckBoxData: [] })
    if(!this.state.postedOrderData[index]?.customer?.qbCustomerId){
      message.error("Customer doesn't have Quickbooks mapping");
    }else{
      this.setState ((prevState) => ({
        isSyncing: true,
        syncPosted : true,
        // selectedIndex: prevState.selectedIndex.filter(i => i !== index),
        singleSync : [...this.state.singleSync,index],
        isSingleSyncing:true
      }));
      const invoice = this.state.postedOrderData[index];
      const invoiceIds = invoice.isRecivePaymentInvoicesId; 
      const newIndices = invoiceIds.map((invoiceId) => this.state.postedOrderData.findIndex(item => item.invoiceId === invoiceId && item.qbInvoiceId === ""))
        .filter((foundIndex) => foundIndex !== -1 && !this.state.singleSync.includes(foundIndex));
      if (newIndices.length > 0) {
        this.setState((prevState) => ({
          singleSync: [...prevState.singleSync, ...newIndices]
        }));
      }
      const payload = {
        order: this.state.postedOrderData[index],
      };
      const details = await this.props.syncPostedOrder(payload);
      let postInvoiceData = _.cloneDeep(this.state.postedOrderData);
      postInvoiceData[index].isSynced = true;
      if(newIndices.length > 0){
        const newIndex = newIndices[0]
        postInvoiceData[newIndex].qbInvoiceId = details.action.payload.qbInvoiceId
      }else if(!payload.order.isRecivePaymentInvoice){
        postInvoiceData[index].qbInvoiceId = details.action.payload.qbInvoiceId
      }
      this.setState((prevState) => ({
        postedOrderData: postInvoiceData,
        isSyncing: false,
        // selectedIndex: [],
        singleSync: prevState.singleSync.filter(i => i !== index && !newIndices.includes(i)), 
        syncPosted: false,
        isSingleSyncing:false
      }));  
    }
  };

  lockPostedOrder = async (index) => {
    const payload = {
      _id: this.state.postedOrderData[index]._id,
      notes: this.state.postedOrderData[index].notes,
      total: this.state.postedOrderData[index].total,
      orders: this.state.postedOrderData[index],
      updateType: "finalized",
      billingStatus: "finalized",
    };
    const {
      value: { status },
    } = await this.props.updateInvoiceOrder(payload);
    if (status) {
      this.setState({ pleaseWait: false });
      this.setState({ postPleaseWait: false });
      const searchText = "";
      this.fetchPostedOrderData(
        searchText,
        this.state.startDate,
        this.state.endDate
      );
    }
  };
  backToNeedReview = async (index) => {
    const payload = {
      _id: this.state.postedOrderData[index]._id,
      notes: this.state.postedOrderData[index].notes,
      total: this.state.postedOrderData[index].total,
      orders: this.state.postedOrderData[index],
      balacnce: this.state.postedOrderData[index].balacnce,
      updateType: "needReview",
      billingStatus: "needReview",
      attachments: this.state.postedOrderData[index].attachments,
    };
    const {
      value: { status },
    } = await this.props.updateInvoiceOrder(payload);
    if (status) {
      this.setState({ pleaseWait: false });
      this.setState({ postPleaseWait: false });
      const searchText = "";
      this.fetchPostedOrderData(
        searchText,
        this.state.startDate,
        this.state.endDate
      );
    }
  };
  encrypt(data, logo, customerName, viewInvoiceUrl, company,userId) {
    data.logo = logo;
    data.customerName = customerName;
    data.viewInvoiceUrl = viewInvoiceUrl;
    data.company = company;
    data.userId=userId
    return CryptoJS.AES.encrypt(JSON.stringify(data), "secret-key").toString();
  }
  linkToPayment = (index) => {
    const postedOrderData = this.state.postedOrderData[index];
    const companyData = this.state.companyData;
    const user=this.state.userData
    const data = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: postedOrderData?.customer?.customerName,
        customerId: postedOrderData?.customer?._id,
        chargeStripeCardFees : companyData?.chargeStripeCardFees,
      }, customer: {
        email: postedOrderData?.customer.email,
        billingEmailAddress: postedOrderData?.customer?.billingEmailAddress,
        companyName: postedOrderData?.customer?.companyName,
        customerName:postedOrderData?.customer?.customerName,
      },
      invoice: {
        invoiceId: postedOrderData?.invoiceId,
        dueDate: postedOrderData?.dueDate,
        total: postedOrderData?.total,
        balance:
          Number(postedOrderData?.total) - Number(postedOrderData.amountPaid),
        amountPaid: postedOrderData?.amountPaid,
      },
    }
    const {company , invoice , customer} = data
    let viewInvoiceUrl = `${this.state.API_URL}/invoice_send?invoiceId=${_.get(
      data.invoice,
      "invoiceId",
      ""
    )}&companyId=${_.get(company, "companyId", "")}`;
   const encrypted = this.encrypt(invoice, _.get(company, "logo", ''), customer.customerName, viewInvoiceUrl, company,user._id)
    this.props.navigate(
      `/review-pay-invoice?${encrypted}`
    );
  }

  changeLimit = async (e)=>{
    this.setState({limit: e.target.value === "results" ? 20 : Number(e.target.value)},
    () => {
      this.fetchPostedOrderData(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
        );
      }
      )
      await localStorage.setItem("postedLimit", e.target.value === "results" ? "20" : e.target.value)

  }
  editPaymentReceipts = async (index)=>{
    this.props.history.push(
      `/edit-payment-receipts/${this.state.postedOrderData[index]._id}`
    );
  }

  render() {
    console.log(this.state.singleSync,this.state.selectedIndex,"==============selectedIndex")
    const { startDate, endDate, searchText,userData, postedOrderData } = this.state;
    return (
      <div className="posted__wrapper">
        <section className="table__filter-section">
        <>
          <div className="table__filter-inner">
            <div className="filter-form">
              <DebounceInput
                type="text"
                value={this.state.searchText.trimStart()}
                onChange={(e) => {
                  this.props.handleSearchText(e.target.value.trimStart(), this.state.searchStartsWith);
                  this.setState({
                     searchText: e.target.value.trimStart(),
                    //  postedOrderData: [],
                     totalItems: 0,
                     currentPage:1,
                     skip:0,
                     isLoading: this.state.searchText.trimStart()!=="" && postedOrderData.length === 0
                    }, () => {
                    const func = () => {
                      (this.state.searchText.length === 0 || e?.target?.value?.length === 0 || e?.target?.value?.trim().length) && this.fetchPostedOrderData(
                        this.state.searchText?.trim(),
                        this.state.startDate,
                        this.state.endDate,
                        10
                        )
                    }
                    func()
                  });
                }}
                placeholder="Search by company name or invoice #... "
                className="form-control"
                minLength={2}
                debounceTimeout={1000}
              />
              <button
                onClick={() => {
                  this.setState({ visible: true });
                }}
                className="btn btn-dark btn-sm btn-outline-black filter-btn"
              >
                {/* <img className="icon" src={filterIcon} alt="" /> */}
                <FilterIcon />
                Filter{" "}
                {this.state.filtersData.length > 0 &&
                this.state.filtersData.filter(item => item !== "payment").length}
              </button>
            </div>
            <div className="d-flex align-items-center w-auto">
              <div className="custom-select-spiner ml-2">
                {this.state.spinning && (
                  <Spin indicator={antIcon} spinning={true} />
                )}
                <select
                  className="form-control custom-select material-textfield-input batch-action-btn"
                  name="dateType"
                  required
                  value={this.state.batchAction}
                  onChange={(e) => {
                    this.bulkOptions(e);
                  }}
                  disabled={!this.state.selectedCheckBoxData.length > 0}
                >
                  {!this.state.spinning && (
                    <>
                      <option value="">Batch Action</option>
                      <option value="send">Send</option>
                      <option value="print" >Print</option>
                      <option value="download">Download</option>
                      <option value="syncwithqb">Sync with Quickbooks</option>
                      {
                        !this.state.postedOrderData.filter(e=> e.checkBox === true).some(e => e?.isRecivePaymentInvoice) && <option value="reminderInvoice">Reminder invoices</option>
                      }
                      <option value="lock">Lock & move to Finalized</option>
                      <option value="needReview" disabled={this.state.optionDisable}>Move back to Needs Review</option>
                    </>
                  )}
                </select>
              </div>              
              <select
                className="form-control custom-select material-textfield-input batch-action-btn result-select ml-2"
                value={this.state.limit.toString()}
                onChange={(e) => {
                  this.changeLimit(e)
                }}
              >
                <option value="results">Results</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
          </>
        </section>
        <SearchCheckboxComponent
          searchStartsWith={this.state.searchStartsWith}
          handleSearchStartsWith={(value) =>
            this.setState({
              searchStartsWith: value
            }, () => {
              this.state.searchText!== "" && this.fetchPostedOrderData(this.state.searchText?.trim(), this.state.startDate, this.state.endDate, 10)
              this.props.handleSearchText(this.state.searchText, this.state.searchStartsWith)
            })
          }
        />
        <section className="table__section">
          <div className="card">
            <div ref={this.tableRef} className="table-responsive">
              
              <table className="table custom-table fixTableHead">
                <thead>
                  <tr>
                    <th className="first-child" onClick={() => this.handleSortClick("customerId")}>
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          checked={
                            this.state.selectedCheckBoxData.length > 0 &&
                            this.state.postedOrderData.length ===
                              this.state.selectedCheckBoxData.length
                          }
                          onChange={() => {
                            this.selectedCheckBox(
                              this.state.postedOrderData,
                              "all"
                            );
                          }}
                          type="checkbox"
                          className="custom-checkbox--input"
                        />
                        <span className="checkmark"></span>
                      </label>
                      Customer
                      {this.state.sortOrder["customerId"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                    </th>
                    <th onClick={() => this.handleSortClick("invoiceId")}>
                      Invoice #
                      {this.state.sortOrder["invoiceId"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                    </th>
                    <th >
                      QB Sync
                    </th>
                    <th onClick={() => this.handleSortClick("invoiceDate")}>
                      Invoice Date
                      {this.state.sortOrder["invoiceDate"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("dueDate")}>
                      Due Date
                      {this.state.sortOrder["dueDate"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("invoiceType")}>
                      Type
                      {this.state.sortOrder["invoiceType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th > {/* "method"  not a key in db */}
                      Method
                    </th>
                    <th onClick={() => this.handleSortClick("balacnce")}>
                      Balance
                      {this.state.sortOrder["balacnce"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("total")}>
                      Total
                      {this.state.sortOrder["total"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("invoiceDelivery")}>
                      Delivery Method
                      {this.state.sortOrder["invoiceDelivery"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("deliveryStatus")}>
                      Delivery Status
                      {this.state.sortOrder["deliveryStatus"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th onClick={() => this.handleSortClick("paymentStatus")}>
                      Status
                      {this.state.sortOrder["paymentStatus"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />} 
                    </th>
                    <th className="pr-40">Action</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.isLoading ? this.state.postedOrderData.length > 0 ? (
                    this.state.postedOrderData.map((item, index) => (
                      <tr>
                        <td className="first-child underline">
                            <label className="custom-checkbox custom-checkbox-line radio__checkbox underline">
                              <input
                                checked={item.checkBox}
                                onChange={() => {
                                  this.selectedCheckBox(item, "single", index);
                                }}
                                type="checkbox"
                                className="custom-checkbox--input"
                              />
                              <span className="checkmark"></span>
                            </label>
                          <span
                            onClick={() => {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                        
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/general`
                              );
                            }}
                          >
                            {item?.customer?.displayName !== ""
                              ? item?.customer?.displayName
                              : "N/A"}
                          </span>
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="underline"
                        >
                          {item?.invoiceId}
                        </td>
                        {!this.state.isQbData 
                        ?
                          <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>
                          <button className="btn btn-dark setupqb" onClick={()=>{
                            this.props.navigate(
                              `/integrations`
                            );
                          }}>Setup QB</button>
                        </td>
                        :
                        ((item.isRecivePaymentInvoice) && userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true) ? (
                          <td>
                            {(this.state.isSingleSyncing || this.state.isBulkSyncing) &&
                            // this.state.selectedIndex?.find(
                            //   (e) => e === index || e === item?.invoiceId
                            // )
                            ((this.state.singleSync.includes(index) && this.state.isSingleSyncing) || (this.state.selectedIndex.includes(index) && this.state.isBulkSyncing))
                             ? (
                              <Spin
                                size="small"
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 20, color: "#000" }}
                                    spin
                                  />
                                }
                              />
                            ) : (!_.get(item, "isSynced", false)) && this.state.selectedIndex.length > 0 && this.state.isBulkSyncing ? 
                              <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon gray"
                              />
                             :_.get(item, "isSynced", false) ? (
                              <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon green"
                              />
                            ) : (
                              <SyncIcon
                                onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon red"
                              />
                            )}
                          </td>
                        ) : (!(item.isRecivePaymentInvoice) && userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true) ? (
                            <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>
                            { 
                              (this.state.isSingleSyncing || this.state.isBulkSyncing) &&
                              ((this.state.singleSync.includes(index) && this.state.isSingleSyncing ) || (this.state.selectedIndex.includes(index) && this.state.isBulkSyncing))
                              ? (
                                <>
                                <Spin
                                        size="small"
                                        indicator={<LoadingOutlined
                                          style={{ fontSize: 20, color: "#000" }}
                                          spin />} /></>
                              ) : (!item?.qbInvoiceId) && this.state.selectedIndex.length > 0 && this.state.isBulkSyncing ? 
                                <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon gray"
                              />
                             :(! item?.qbInvoiceId) ? (
                                <SyncIcon
                                  onClick={() => this.syncPostedOrder(index)}
                                  className="icon btn-icon red"
                                />
                              )
                             : (
                              <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon green"
                              />
                            )}
                          </td>
                        ) : (
                            <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>-</td>
                        )}
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                        >
                          {moment(item?.invoiceDate)?.tz(this.state?.tz)?.format("L")}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {item?.dueDate
                            ? moment(item?.dueDate)?.tz(this.state?.tz)?.format("L")
                            : "-"}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {item?.invoiceType.charAt(0).toUpperCase() + item?.invoiceType.slice(1)}
                        </td>
                        <td className="text-capitalize">
                          {item?.isRecivePaymentInvoice ? paymentMethodPosted(item) :"-"}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {item?.balacnce
                            ? `$${this.numberWithCommas(
                                Number(item?.balacnce)
                              )}`
                            : "-"}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {item?.total
                            ? `$${this.numberWithCommas(Number(item?.total))}`
                            : "-"}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {item?.invoiceDelivery ? item?.invoiceDelivery : "-"}
                        </td>
                        <td
                          onClick={() => {
                            if (item?.isRecivePaymentInvoice) {
                              sessionStorage.removeItem('need_review_filtersData');
                              sessionStorage.removeItem('finalized_filtersData');
                              sessionStorage.removeItem('posted_filtersData');
                              this.setState({
                                isCustomerModelOpen: true,
                                customerId: item?.customer?.customerId,
                              });
                              this.props.navigate(
                                `/posted/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                              );
                            } else {
                              this.modelOpen(index);
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {!item?.isRecivePaymentInvoice ? (
                            <DeliveryStatusIcon
                              className={
                                item?.deliveryStatus
                                  ? "icon icon-green"
                                  : "icon icon-gray"
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {/* <button className={item?.balacnce === 0 ? "btn btn-for-code btn-green " : "btn btn-for-cc"}> {item?.balacnce === 0 ? "Paid" : "open"}</button> */}
                          {(item?.paymentStatus === "Open" ||
                            item?.paymentStatus === "open") && (
                            <button className="btn btn-for-cc">Open</button>
                          )}
                          {(item?.paymentStatus === "Overdue" ||
                            item?.paymentStatus === "overdue") && (
                            <button className="btn red-btn-status">
                              Over Due
                            </button>
                          )}
                          {(item?.paymentStatus === "Applied" ||
                            item?.paymentStatus === "applied") && (
                            <button className="btn gray-btn-status">
                              Applied
                            </button>
                          )}
                          {(item?.paymentStatus === "Paid" ||
                            item?.paymentStatus === "paid") && (
                            <button className="btn btn-for-code btn-green">
                              Paid
                            </button>
                          )}
                          {(item?.paymentStatus === "Voided" ||
                            item?.paymentStatus === "voided") && (
                            <button className="btn btn-for-code btn-voided">
                              Voided
                            </button>
                          )}
                        </td>
                        <td className="last-child position-relative pr-40">
                          {(item?.balacnce > 0 && item?.paymentStatus !== "applied") ? (
                            <button
                              onClick={() => {
                                this.props.navigate(
                                  `/posted/receive-payment/${item?.customerId}`
                                );
                              }}
                              className="btn btn-dark btn-small"
                            >
                              Pay
                            </button>
                          ) : (
                            <div className="dash-center">-</div>
                          )}
                        </td>
                        <td className="sticky-block">
                          <Dropdown
                            overlay={() => this.menu(index)}
                            trigger={["click"]}
                            overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                          >
                            <button className="btn ant-dropdown-link three-dot">
                              <MoreIcon />
                            </button>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <div className="empty__section">
                            <div className="empty__section-inner">
                              <p className="empty__text">No Posted Invoices</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ): (
                    <>
                      <tr>
                        <td colSpan={12}>
                          <div className="empty__section">
                            <div className="empty__section-inner">
                              <Spin indicator={antIcon} spinning={this.state.isLoading} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  {/* <tr>
                    <td className="first-child">
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox underline">
                        City Builders
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                    <td className="underline">2345</td>
                    <td><SyncIcon className="icon"/></td>
                    <td>01/01/2023</td>
                    <td>02/01/2023</td>
                    <td>Invoice</td>
                    <td>$150.00</td>
                    <td>$250.00</td>                    
                    <td>Email</td>
                    <td><DeliveryStatusIcon className="icon icon-gray"/></td>
                    <td><button className="btn btn-green btn-for-cc">Paid</button></td>
                    <td className="last-child">
                      <button className="btn btn-dark btn-small">Pay</button>
                      <Dropdown
                        overlay={() => this.menu()}
                        trigger={["click"]}
                        overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                      >
                        <button className="btn ant-dropdown-link">
                          <MoreIcon />
                        </button>
                      </Dropdown>
                    </td>
                  </tr> */}
                </tbody>
              </table>
             
              <Pagination
                className="text-center"
                onChange={this.onChangePage}
                hideOnSinglePage
                current={this.state.currentPage}
                total={this.state.totalItems}
                pageSize={this.state.limit}
                showSizeChanger={false}
              />
            </div>
          </div>
        </section>
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="select__driver-drawer"
        >
          <div className="head-section-header d-flex align-items-center justify-content-between">
            <button
              className="btn btn-dark btn-sm clear-btn"
              onClick={() => {
                this.setState(
                  { filtersData: [], reRender: !this.state.reRender },
                  () => {
                    sessionStorage.removeItem('need_review_filtersData')
                    sessionStorage.removeItem('finalized_filtersData')
                    sessionStorage.removeItem('posted_filtersData')
                    this.fetchPostedOrderData(searchText, startDate, endDate);
                  }
                );
              }}
            >
              Clear
            </button>
            <div className="head-section-title">Filters</div>
            <div className="head-section-cta">
              <div onClick={this.onClose} className="btn btn-link btn-back">
                <CloseIcon />
              </div>
            </div>
          </div>
          <ul className="filter__list">
            <>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("open")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("open")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Open"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("paid")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("paid")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Paid"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("applied")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("applied")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Applied"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("overdue")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("overdue")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Over Due"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("voided")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("voided")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Voided"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("payment")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("payment")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Payment"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="sub-manu">
                <ul className="sub-manu-list">
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                         onChange={(e) => {
                          this.openFilterList("cash");
                         }}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("cash")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Cash"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("check")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("check")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Check"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("credit-card")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("credit-card")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Credit Card"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("credit-memo")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("credit-memo")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Credit Memo"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("terminal")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("terminal")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Terminal"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("wire")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("wire")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Wire"}</p>
                      </label>
                    </span>
                  </li>
                </ul>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("invoice")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("invoice")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Invoice"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("nonSyncedToQb")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("nonSyncedToQb")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Awaiting Quickbooks Sync"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                  <input
                      onChange={(e) => this.openFilterList("syncedToQb")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("syncedToQb")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Synced to Quickbooks"}</p>
                  </label>
                </span>
                <div
                ></div>
              </li>
            </>
          </ul>
        </Drawer>
        <div className="total-amount-list">
          <div className="total-amount-list-items">
            <h3 className="title">Total Invoices</h3> 
            : ${this.numberWithCommas(this.state?.invoicesTotal && this.state?.invoicesTotal > 0 ? this.state.invoicesTotal : 0)} 
          </div>
          <div className="total-amount-list-items">
            <h3 className="title">Total Payments</h3> 
            : ${this.numberWithCommas(this.state?.paymentsTotal && this.state?.paymentsTotal > 0 ? this.state.paymentsTotal : 0)}
          </div>
        </div>        
        {this.state.isPrintReciptt && (
          <div className="pdf-container" id="pdf-container">
            <InvoicePDF
              printRecipt={this.state.modelData}
              printReciptdatanew={this.state.modelData2}
              closePrintRecipt={() => {
                this.closePrintRecipt();
              }}
            />
          </div>
        )}
        {this.state.isPrintReciptposted && (
          <div className="pdf-container" id="pdf-container">
            <PrintInvoiceModal
              printRecipt={this.state.modelData}
              printReciptdatanew={this.state.modelData2}
              closePrintRecipt={() => {
                this.closePrintRecipt();
              }}
            />
          </div>
        )}
        {this.state.isModelOpen && (
          <InvoiceDetailComponent
            updateinvoiceDetailData={this.updateinvoiceDetailData}
            isModelOpen={this.state.isModelOpen}
            closeModel={this.closeModel}
            modelData={this.state.modelData}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            reArrangOrdersData={this.reArrangOrdersData}
            companyData={this.state.companyData}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            fetchOrder={this.props.fetchOrder}
            {...this.props}
          />
        )}
        {this.state.isCustomerModelOpen && (
          <CustomerDetailComponent
            currentPage={this.state.currentPage}
            syncPostedOrder={this.props.syncPostedOrder}
            customerId={this.state.customerId}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            changeUrl={this.assignUrl}
            closeModel={this.closeCustomerDetatilModel}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            defaultUrl={this.state.defaultUrl}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={
              this.props.getCustomercreditsForCustomers
            }
            getReceivePaymentForCustomers={
              this.props.getReceivePaymentForCustomers
            }
            customerInvoicesByCustomerId={
              this.props.customerInvoicesByCustomerId
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}
