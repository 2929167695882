import React, { PureComponent } from "react";
import "./styles.scss";
import { CloseIcon } from "../../components/icons";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import MoneyInput from "./components/MoneyInput";
import { Drawer } from "antd";
import _ from "lodash";
import AddCardComponent from "../payabli/addNewCard";
import PECardModal from "../PendingOrderDetails/partials/credit-card-payengine-modal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import NumberFormat from "react-number-format";
import {generateUniqueNum, numberFormat } from "../../constants/common";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



export default class ReceivePaymentComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      receivePayment: [],
      date: new Date(),
      isDatePickerOpen: false,
      paymentMethodType: {
        paymentType: "cash",
        PaymentMethodTypeStore: {
          type: "referenceNumber",
          value: "",
        },
      },
      amountReceive: 0.0,
      reRender: false,
      memo: "",
      uuid : "",
      attachments: [],
      showAttachments: [],
      amountToApply: 0,
      pleaseWait: false,
      totalAmmount: 0.0,
      selectedInvoices: [],
      totalCreditAmount: 0,
      customerData: {},
      creditMemoNumber: "",
      error: {},
      isDisable: false,
      totalCustomerCreditAmount: null,
      tempInvoiceIndex: [],
      customerCredits: [],
      // pleaseWait: false,
      // totalAmmount:"",
      // selectedInvoices: [],
      checked: false,
      invoiceCreditIndex:[],
      creditMemoSum : 0 , 
      customers: [],
      isCompany: false,
      visible: false,
      typingTimeout: 0,
      companySearchText: "",
      selectedCustomer: null,
      initialValues: {
        customer: "",
      },
      paybliDetails:{},
      paybliEnable:false,
      isAddNewCard:false,
      customerPayabliData:{
        StoredMethods:[]
      },
      payMethod:"",
      cardId:"",
      tz,
      cardSpinner:false
    };
    this.payabliRef = React.createRef();
  }
  componentDidMount = async () => {
    window.addEventListener('wheel', this.handleScroll);
    let UUID = generateUniqueNum();
    this.setState({uuid : UUID})
    const url = window.location.pathname;
    const urlArray = url.split("/");
    this.fetchMe()
    this.fetchInvoices(urlArray[1], urlArray[3]);
    this.fetchCustomers()
    this.getPaybliIntegration()
  };
  found = (_id) => {
    if (this.state.selectedInvoices.length > 0) {
      const arr = this.state.selectedInvoices?.filter((inv) => inv._id === _id);
      return arr.length > 0;
    } else {
      return false;
    }
  };
  foundAndUnchecked = (inv) => {
    return !inv.showCheck;
  };
  handleScroll = () => {
    const input = document.activeElement;
    if (input && input.tagName === 'INPUT' && input.type === 'number') {
      input.blur();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    window.addEventListener('wheel', this.handleScroll);
    if (
      prevState.selectedInvoices !== this.state.selectedInvoices ||
      prevState.totalAmmount !== this.state.totalAmmount
    ) {
      if (this.state.paymentMethodType.paymentType !== "credit-memo") {
        const ammount = this.state.totalAmmount;
        const sum = this.state.selectedInvoices.reduce(
          (accumulator, currentValue) => Number((accumulator + currentValue.balacnce).toFixed(2)),
          0
        );
        let remaining = ammount;
        let amountToApply = 0;
        if (this.state.selectedInvoices.length > 0 && sum < ammount) {
          const newInvoicesArr = this.state.receivePayment?.map((v) => {
            const thisInvoice = v;
            const invoiceLess = remaining > thisInvoice.payment;
            if (this.found(thisInvoice._id)) {
              if (invoiceLess) {
                const payment = thisInvoice.balacnce;
                amountToApply += !isNaN(thisInvoice.payment) ? Number(
                  thisInvoice.isHardCoded ? thisInvoice.payment : payment
                ):0
                remaining = remaining - payment;
                return {
                  ...v,
                  payment: thisInvoice.isHardCoded
                    ? thisInvoice.payment
                    : Number(Number(payment).toFixed(2)),
                  amountToShow: thisInvoice.isHardCoded
                    ? thisInvoice.amountToShow
                    : Number(Number(payment).toFixed(2)),
                };
              } else {
                return { ...v };
              }
            } else {
              return {
                ...v,
                payment: 0,
                amountToShow: 0,
                creditAmount: 0,
                isHardCoded: false,
              };
            }
          });
          this.setState({
            receivePayment: newInvoicesArr,
            amountReceive: remaining ?? 0,
            totalCreditAmount: this.state.totalAmmount - amountToApply,
            amountToApply: amountToApply,
          });
        } else if (this.state.selectedInvoices.length > 0) {
          const newInvoicesArr = this.state.receivePayment?.map((v) => {
            const thisInvoice = v;
            const invoiceLess = remaining > thisInvoice.balacnce;
            if (this.found(thisInvoice._id)) {
              if (invoiceLess) {
                const payment = thisInvoice.isHardCoded ? Number(thisInvoice.payment) : thisInvoice.balacnce;
                remaining = remaining - payment;
                amountToApply += !isNaN(thisInvoice.payment) ? Number(
                  thisInvoice.isHardCoded ? thisInvoice.payment : payment
                ):0
                return {
                  ...v,
                  payment: thisInvoice.isHardCoded
                    ? thisInvoice.payment
                    : Number(Number(payment).toFixed(2)),
                  amountToShow:thisInvoice.isHardCoded ? Number(
                     thisInvoice.amountToShow
                  ) : Number(Number(payment).toFixed(2)),
                };
              } else {
                const payment = thisInvoice.isHardCoded ? Number(thisInvoice.payment) : remaining;
                remaining = remaining - payment;
                amountToApply += !isNaN(thisInvoice.payment) ? thisInvoice.isHardCoded ? Number(
                   thisInvoice.payment
                ):Number(Number(payment).toFixed(2)):0
                return {
                  ...v,
                  payment: thisInvoice.isHardCoded
                    ? thisInvoice.payment
                    : Number(Number(payment).toFixed(2)),
                  amountToShow:thisInvoice.isHardCoded ? Number(
                     thisInvoice.amountToShow 
                  ): Number(Number(payment).toFixed(2)),
                };
              }
            } else {
              return {
                ...v,
                payment: 0,
                amountToShow: 0,
                creditAmount: 0,
                isHardCoded: false,
              };
            }
          });
          this.setState({
            receivePayment: newInvoicesArr,
            amountReceive: remaining,
            totalCreditAmount: this.state.totalAmmount - amountToApply,
            amountToApply: amountToApply,
          });
        } else {
          const newInvoicesArr = this.state.receivePayment?.map((v) => ({
            ...v,
            payment: 0,
            amountToShow: 0,
            creditAmount: 0,
            isHardCoded: false,
          }));
          this.setState({
            receivePayment: newInvoicesArr,
            amountReceive: ammount,
            amountToApply: 0.0,
            totalCreditAmount: this.state.totalAmmount,
          });
        }
        this.setState({ amountReceive: ammount > sum ? sum : ammount });
        // this.setState({ amountToApply: ammount > sum ? sum : ammount,amountReceive:ammount > sum ? sum : ammount });
      } 
    }
      }

  getPaybliIntegration = async () => {
    const {
      value: { status, data },
    } = await this.props.getPaybliIntegration();
    if (status) {
      this.setState({ paybliEnable: true,
        paybliDetails: data});
    }
  };
  fetchMe = async () => {
    const {
      value: { user },
    } = await this.props.fetchMe();
    this.setState({ user });
  };

  fetchInvoices = async (billingStatus, customerId) => {
    const payload = {
      customerId: customerId,
      billingStatus: billingStatus,
    };
    const {
      value: { data },
    } = await this.props.getRecivingPaymentInvoices(payload);
    if (data) {
      this.fetchcustomerCredits(customerId);
    }
    if (data?.customerInvoice && data?.customerInvoice.length > 0) {
      let receivePayment = [];
      let totalBalance = 0;
      let tempInvoiceIndex = [];
      data.customerInvoice.map((item) => {
        tempInvoiceIndex.push("0");
        receivePayment.push({
          ...item,
          payment: 0,
          creditAmount: 0,
          isHardCoded: false,
          amountToShow: 0,
          balacnce:Number(item.balacnce.toFixed(2))
        });
        totalBalance = totalBalance + Number(item?.balacnce.toFixed(2));
        // totalBalance = totalBalance +item.balacnce;
        return null;
      });
      this.setState({
        receivePayment: receivePayment,
        selectedCustomer: receivePayment[0]?.customer,
        tempInvoiceIndex: tempInvoiceIndex,
        amountToApply: 0,
        // amountToApply: totalBalance,
        customerData: data?.customerInvoice[0].customer,
      });
    }
  };

  fetchcustomerCredits = async (customerId) => {
    let creditsArray = [];
    const payload = {
      customerId: customerId,
    };
    const {
      value: { data },
    } = await this.props.getCustomercredits(payload);
    let creditMemoSums = 0;
    if (data?.customercredits?.length > 0) {
      data.customercredits.map((item) => {
        item.receiveCreditAmount =  item?.receiveCreditAmount;
        creditMemoSums = creditMemoSums + parseFloat(item?.receiveCreditAmount);
        creditsArray.push(item);
        return null;
      });
      this.setState({
        customerCredits: creditsArray,
        creditMemoSum: creditMemoSums,
      });
    }
  };
  numberWithCommas = (x) => {
    x=parseFloat(x)
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };

  handlePaymnetForCredit = (e, index, balance) => {
    if ( (e <= this.state.totalCustomerCreditAmount)) { 
    let receivePayment = this.state.receivePayment
    let amountReceive = 0.00
    let totalcreditAmount = 0.00
    let creditAmount = 0
    let temptotalrecivePayment = 0
    let invoiceCreditIndex=this.state.invoiceCreditIndex
    let tempInvoiceIndex = this.state.tempInvoiceIndex
        tempInvoiceIndex[index] = Number(e)
        tempInvoiceIndex.map((i) => {
      temptotalrecivePayment = temptotalrecivePayment + Number(i)
      return null
    })
    if (this.state.paymentMethodType?.paymentType === "credit-memo" && this.state.creditMemoNumber !== '') {
      if (temptotalrecivePayment <= this.state.totalCustomerCreditAmount) {
        if (balance >= e) {
          receivePayment[index].payment = e
        }
        // receivePayment[index].payment = e
      }
    } else {
      receivePayment[index].payment = e
      if (balance < e) {
        creditAmount = (e) - (receivePayment[index].balacnce)
        invoiceCreditIndex[index]= Number(creditAmount)
        // totalcreditAmount = totalcreditAmount + creditAmount
      }else{
        invoiceCreditIndex[index]=Number(0)
      }
      invoiceCreditIndex.map((i)=>{
     totalcreditAmount= Number(totalcreditAmount) + Number(i)
     return null
      })
    }
    receivePayment[index]["creditAmount"] = creditAmount
    receivePayment.forEach((item) => {
      amountReceive = Number(amountReceive) + Number(item?.payment)
    })
    let error=this.state.error;
    let isDisable=this.state.isDisable
    if(Object.keys(error)?.length > 1){
      isDisable=this.state.isDisable
    }else{
      isDisable=false
    }
    error.payment=""
    this.setState({ amountReceive: amountReceive ,  isDisable:isDisable,
      error:error})
      this.setState({ receivePayment: receivePayment,totalCreditAmount: totalcreditAmount.toFixed(2), reRender: !this.state.reRender })
  }else{
    this.setState({reRender: !this.state.reRender})
  }
  };

  calcFee(amount, currency,fixedDivideIn) {
    let feeAmount = 0
    const {user} = this.state
    if(amount > 0){
      const _fee = user?.company?.chargeStripeCardFeesAmount ? user?.company?.chargeStripeCardFeesAmount : "3"
      amount = this.round2decimal(amount);
      let fee =
      amount * (this.round2decimal(_fee) / 100) 
      feeAmount = this.round2decimal(fee);
    }
     return feeAmount;
  }


  async chargeCustomer(totalAmountToPay,orderId,invoiceId) {
    if (this.state.payMethod === "payengine") {
      try {
        let { cardId } = this.state;
        let totalAmount=Number(totalAmountToPay)
        // if(this.state.user?.company?.chargeStripeCardFees){
        // totalAmount=Number(totalAmountToPay) + Number(this.calcFee(totalAmountToPay,"USD"))
        // }
        const {
          value: { status, data },
        } = await this.props.chargeCustomer({
          amount: totalAmount,
          cardId,
          customerId: this.state.customerData._id,
          orderId,
          uniqueId : this.state.uuid,
          invoiceId,
          createCharge: true,
          paymentReceipt:true,
          cardFee:this.state.user?.company?.chargeStripeCardFees ? 0 : 0
        });
        if (status) {
          return data;
        }
        else{
          let UUID = generateUniqueNum();
          this.setState({uuid : UUID})
        }
      } catch (err) {
        if (err.message.indexOf("No such source") !== -1) {
          return false;
        } else {
          return false;
        }
      }
    }else  {
      return true;
    }
  }

  handlePaymnet = (value, index, balance, items) => {
    const e = value ? value: ""
    let receivePayment = this.state.receivePayment;
    let amountReceive = 0;
    let totalcreditAmount = 0;
    let creditAmount = 0;
    let temptotalrecivePayment = 0;
    let tempInvoiceIndex = this.state.tempInvoiceIndex;
    tempInvoiceIndex[index] = e;
    console.log(totalcreditAmount)
    tempInvoiceIndex.map((i) => {
      temptotalrecivePayment = temptotalrecivePayment + Number(i);
      return null;
    });
    if (
      this.state.paymentMethodType?.paymentType === "credit-memo" &&
      this.state.creditMemoNumber !== ""
    ) {
      if (temptotalrecivePayment <= this.state.totalCustomerCreditAmount) {
        if (balance >= e) {
          receivePayment[index].payment = e;
        }
        receivePayment[index].payment = e;
      }
    } else {
      receivePayment[index].payment = Number(e) > balance ? balance : Number(e);
      receivePayment[index].amountToShow = e;
      receivePayment[index].isHardCoded = true;
      receivePayment[index].creditAmount =
        e > balance ? Number(e) - balance : 0;

      if (balance < e) {
        creditAmount = e - receivePayment[index].balacnce;
        totalcreditAmount = totalcreditAmount + creditAmount;
      }
      const filteredSelected = receivePayment.filter(
        (inv) => inv?._id !== items?._id 
      );
      const sumOfPayment = receivePayment.reduce(
        (accumulator, currentValue) =>{
          const currentPaymnt = isNaN(currentValue.payment) ? 0 : Number(currentValue.payment)
          return accumulator + Number(currentPaymnt)
        }
          ,
        0
      );
      const sumOfAmount = filteredSelected.reduce(
        (accumulator, currentValue) =>{
          const currentAmmount = isNaN(currentValue.amountToShow) ? 0 : Number(currentValue.amountToShow)
         return accumulator + Number(currentAmmount)},
        0
      );
      if (!this.state.totalAmmount || sumOfAmount + Number(e) > this.state.totalAmmount) {
        this.handleTotalChanged(sumOfAmount + Number(e), true);
      } else {
        this.handleTotalChanged(this.state.totalAmmount, true);
        this.setState({
          amountToApply: isNaN(sumOfPayment) ? 0 : sumOfPayment,
          totalCreditAmount: isNaN(sumOfPayment)
          ? Number(this.state.totalAmmount).toFixed(2)
          : (Number(this.state.totalAmmount) - sumOfPayment).toFixed(2),
        });
      }
    }
    receivePayment[index]["creditAmount"] = creditAmount;
    receivePayment.forEach((item) => {
      amountReceive = Number(amountReceive) + !isNaN(item?.payment) ? Number(item?.payment):0;
    });
    // this.setState({ amountReceive: amountReceive })
    // this.setState({ receivePayment: receivePayment, totalCreditAmount: totalcreditAmount, reRender: !this.state.reRender })
    let error=this.state.error;
    let isDisable=this.state.isDisable
    if(Object.keys(error)?.length > 1){
      isDisable=this.state.isDisable
    }else{
      isDisable=false
    }
    error.payment=""
    this.setState({
      isDisable:isDisable,
       error:error,
      receivePayment: receivePayment,
      reRender: !this.state.reRender,
    });
  };
  handleTotalChanged = (e, fromSingleInput) => {
    const formattedE = Number(e).toFixed(2);
    
    const sumOfShow = this.state.receivePayment.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.amountToShow),
      0
    );
    if (Number(formattedE) < Number(sumOfShow) && !fromSingleInput) {
      const newArr = this.state.receivePayment.map((item, index) => {
        return { ...item, isHardCoded: false };
      });
      this.setState({ receivePayment: newArr });
    }
    if (this.state.paymentMethodType.paymentType === "credit-memo") {
      if (isNaN(Number(formattedE))) {
        this.setState({ totalAmmount: "" });
      } else if (Number(formattedE) < this.state.totalCustomerCreditAmount) {
        this.setState({ totalAmmount: formattedE });
      } else {
        this.setState({ totalAmmount: this.state.totalCustomerCreditAmount });
      }
    } else {
      this.setState({ totalAmmount: formattedE });
    }
  };
  checkedCheck = (e) => {
    const found = this.state.selectedInvoices.filter(
      (inv) => inv._id === e._id
    );
    return found.length > 0;
  };

  handleChangedCheck = (e, items, showCheck,index) => {
    if (e.target.name === "total") {
      const newReceiveArr = this.state.receivePayment.map((inv) => ({
        ...inv,
        showCheck: true,
        isHardCoded:false
      }));
      this.setState({
        selectedInvoices: e.target.checked ? newReceiveArr : [],
      });
      if(e.target.checked){
        const recPayments = this.state.receivePayment.map((v,i)=> ({...v,isHardCoded:false,payment:v.balacnce,amountToShow:v.balacnce}))
        const sumOfAmount = recPayments.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.amountToShow),
          0
        );
        if(!this.state.totalAmmount || Number(sumOfAmount) > Number(this.state.totalAmmount)){
          this.setState({totalAmmount:sumOfAmount.toFixed(2)})
        }
        this.setState({receivePayment:recPayments})
      }else{
        this.setState({totalAmmount:0})
      }
    } else {
      if (e.target.checked) {
        const filteredArr = this.state.selectedInvoices.filter(
          (inv) => inv._id !== items._id
        );
        const newArr = [...filteredArr, { ...items, showCheck: showCheck , isHardCoded:false }];
        this.setState({
          selectedInvoices: newArr,
          checked: !this.state.checked,
        });
        const recPayments = this.state.receivePayment.map((v,i)=> i === index ? ({...v,isHardCoded:false,payment:Number(Number(v.balacnce).toFixed(2)),amountToShow:Number(Number(v.balacnce).toFixed(2))}):({...v}))
        const sumOfAmount = recPayments.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.amountToShow),
          0
        );
        if(!this.state.totalAmmount || Number(sumOfAmount) > Number(this.state.totalAmmount)){
        }
        this.setState({totalAmmount:sumOfAmount.toFixed(2)})
        this.setState({receivePayment:recPayments})
      } else {
        const newArr = this.state.selectedInvoices.filter(
          (inv) => inv?._id !== items?._id
        );
        const recPayments = this.state.receivePayment.map((v,i)=> i === index ? ({...v,isHardCoded:false,payment:0,amountToShow:0}):({...v}))
        const sumOfAmount = recPayments.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.amountToShow),
          0
        );
        this.setState({
          selectedInvoices: newArr,
          checked: !this.state.checked,
          totalAmmount:sumOfAmount.toFixed(2)
        });
      }
    }
    let error=this.state.error;
    let isDisable=this.state.isDisable
    if(Object.keys(error)?.length > 1){
      isDisable=this.state.isDisable
    }else{
      isDisable=false
    }
    error.payment=""
    this.setState({
      isDisable:isDisable,
       error:error})
  };

  onChangeFile = async (e) => {
    // const file = e.target.files[0];
    let attachments = this.state.attachments;
    let showAttachments = this.state.showAttachments;
    const imageShow = URL.createObjectURL(e.target.files[0]);
    this.getBase64(e.target.files[0], (result) => {
      attachments.push(result);
      showAttachments.push(imageShow);
      this.setState({
        attachments: attachments,
        showAttachments: showAttachments,
        reRender: !this.state.reRender,
      });
    });
    this.setState({ reRender: !this.state.reRender });
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }
  dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  clearPayment = () => {
    let receivePayment = this.state.receivePayment;
    let selected = [];
    this.state.selectedInvoices.map(
      (inv) => !this.foundAndUnchecked(inv) && selected.push(inv)
    );
    this.state.receivePayment.map((item, index) => {
      receivePayment[index].payment = "";
      receivePayment[index].isHardCoded = false;
      receivePayment[index].creditAmount = 0;
      receivePayment[index].amountToShow = 0;
      return null;
    });
    let tempInvoiceIndex=this.state.tempInvoiceIndex
   this.state.tempInvoiceIndex.map((item,index)=>{
    tempInvoiceIndex[index]= ''
    return null
    })
    this.setState({
      receivePayment: receivePayment,
      reRender: !this.state.reRender,
      amountReceive: 0,
      totalCreditAmount: 0,
      totalAmmount: 0,
      amountToApply: 0,
      selectedInvoices: selected,
      tempInvoiceIndex: tempInvoiceIndex
    });
  };

  clearCardDetails = ()=>{
    this.setState({
      paymentMethodType: {
        paymentType: "cash",
        PaymentMethodTypeStore: {
          type: "referenceNumber",
          value: "",
        },
      },
      cardId: "",
      payMethod: "",
      customerPayabliData: {
        StoredMethods: []
      },
    })
  }

  validation = () => {
    const { paymentMethodType, creditMemoNumber, cardId, receivePayment } = this.state;
    const { paymentType, PaymentMethodTypeStore } = paymentMethodType || {};
    let error = {};
    let isDisable = false;

    if (!paymentType) {
      return;
    }

    if (paymentType === "credit-memo" && creditMemoNumber === "") {
      error.creditMemoNumber = "Credit Memo is required";
      isDisable = true;
    } else
      if (paymentType === "check" && !PaymentMethodTypeStore?.value) {
        error.checkNumber = "Check Number is required";
        isDisable = true;
      } else
        if (paymentType === "check" && PaymentMethodTypeStore.value.length > 12) {
          error.payment = "Please make sure the cheque number is less than 13 characters.";
          isDisable = false;
        } else if (paymentType === "credit-card" && (!cardId || cardId === " ")) {
          error.cardNumber = "Please select Credit Card";
          isDisable = true;
        } else if (paymentType === "wire" && !PaymentMethodTypeStore?.value) {
            error.transationId = "Transaction Id is required";
            isDisable = true;
          } else if (paymentType === "wire" && PaymentMethodTypeStore.value.length > 12) {
            error.payment = "Please make sure the Transaction Id is less than 13 characters.";
            isDisable = false;
          } else if (paymentType === "cash" && PaymentMethodTypeStore?.value?.length > 12) {
            error.payment = "Please make sure the Reference Number is less than 13 characters.";
            isDisable = false;
          } else {
            let totalAmountReceived = 0;
            receivePayment.forEach(item => {
              totalAmountReceived += Number(item?.payment);
            });
            if (!(totalAmountReceived > 0)) {
              error.payment = "Please enter Payment";
              isDisable = false;
            } else {
              this.updateInvoice();
            }
          }

    this.setState({ error, isDisable });
  };


  updateInvoice = async () => {
    let receivePayment = this.state.receivePayment;
    this.setState({ pleaseWait: true });
    let attachments = [];
    if (this.state.attachments.length > 0) {
      this.state.attachments.map((items) => {
        attachments.push(this.props.uploadImage(this.submitImages(items)));
        return null;
      });
    }
    const attachImage = await Promise.all(attachments);
    const attachImages = attachImage.map((item) => {
      return item.value.url;
    });
    const chargeStripeCardFees = this.state.user?.company?.chargeStripeCardFees && this.state.paymentMethodType?.paymentType === "credit-card";
    let  decryptedData = ""
    if(!_.isEmpty(this.state.paybliDetails)){
      // let bytes = CryptoJS.AES.decrypt(this.state.paybliDetails.entry_point, 'weigh123!!');
      decryptedData = this.state.paybliDetails.entry_point 
    }
    let paylod = {
      paymentDate: this.state.date,
      paymentMethod: {
        paymentType: this.state.paymentMethodType.paymentType,
        PaymentMethodTypeStore: this.state.paymentMethodType?.PaymentMethodTypeStore,
      } /* this.state.paymentMethodType */,
      memo: this.state.memo,
      customerId: receivePayment[0].customerId,
      invoices: [],
      attachments: attachImages,
      totalCreditAmount: this.state.totalCreditAmount,
      amountToApply: this.state.paymentMethodType.paymentType !== "credit-memo" && this.state.paymentMethodType.paymentType !== 'cash'  ? this.state.amountToApply : this.state.amountReceive,
      amountReceive: this.state.user?.company?.chargeStripeCardFees && this.state.paymentMethodType.paymentType === "credit-card" && this.state.paymentMethodType.paymentType !== 'cash' ? Number(this.state.totalAmmount)+Number(this.calcFee(this.state.totalAmmount,"USD")) : this.state.amountReceive,
      creditMemoNumber: this.state.creditMemoNumber,
      makePaymentDetails: {
        cardData: {
          storedMethodId:this.state.cardId,
            payabliInfo: !this.state.customerPayabliData ? this.state.paymentData   :  this.state.customerPayabliData?.StoredMethods?.find((card)=>{return card.IdPmethod === this.state.cardId})
        },
        paymentDetails: { totalAmount:this.state.user?.company?.chargeStripeCardFees ? Number(this.state.totalAmmount)+Number(this.calcFee(this.state.totalAmmount,"USD")) : this.state.amountReceive },
        customerData: {
          customerId: this.state.payabliCustomerMethod?.customerId,
        },
        entryPoint: decryptedData,
        signatureUrl: this.state.signatureUrl
      },
      userId:this.state.user?._id,
    };
    if (this.state.paymentMethodType?.paymentType === "credit-memo") {
    }
    const totalInvoiceLength = receivePayment.filter(item => item?.payment > 0).length;
      receivePayment.map((item) => {
        if(Number(item?.payment) > 0){
          let invoice = item;
          let balacnce = Number(invoice.balacnce);
          let amountPaid = Number(invoice.amountPaid);
          const feeeAcctoPayment = chargeStripeCardFees ? Number(this.calcFee(invoice.payment,"USD",totalInvoiceLength)) : Number(invoice.stripeCardFees)
          balacnce = invoice.balacnce + feeeAcctoPayment;
          let stripeCardFees = feeeAcctoPayment+ Number(invoice.stripeCardFees);
          let total =  invoice.total + feeeAcctoPayment;
          let nonRoundedTotalAmount = invoice.nonRoundedTotalAmount + feeeAcctoPayment;
          let paymentType = this.state.paymentMethodType?.paymentType === "credit-card" ? "credit-card" : invoice.paymentType;
          const paymentWithFee = Number(invoice.payment) + Number(feeeAcctoPayment)
        if (invoice?.payment > invoice.balacnce) {
          amountPaid = total;
          balacnce = (Number(total) - Number(amountPaid)).toFixed(2) ;
        } else {
          amountPaid =
            Number(invoice?.amountPaid) + Number(paymentWithFee);
          balacnce = (Number(total) - Number(amountPaid)).toFixed(2);
        }
     
        const obj = {
          ...invoice,balacnce,amountPaid,stripeCardFees,total,paymentType,nonRoundedTotalAmount
        };
        delete obj?.customer;
        paylod.invoices.push(obj);
      }
        return null;
      });
    let data = false;
    if(this.state.paymentMethodType?.paymentType === "credit-card"){
    data =  await this.chargeCustomer(paylod.amountReceive,paylod?.invoices[0]?.orderId[0],paylod?.invoices[0]?._id);
    paylod.stripeCardFees=this.state.user?.company?.chargeStripeCardFees ? Number(this.calcFee(this.state.totalAmmount,"USD")):0
    }else{
      data = true;
    }  
    if(data){
      const {
        value
      } = await this.props.updateReceievePayment(data?.paymentHistory?._id ? {...paylod,paymentHistoryId:data.paymentHistory._id} : paylod);
      if (value.status) {
        this.setState({ pleaseWait: false });
        this.props.history.push(
          `/billing/posted-transactions`
          );
        }else{
          const error = this.state.error;
          error.responseError = value.message
          this.setState({ pleaseWait: false, error });
      }
    } else {
      const error = this.state.error;
      error.responseError = "Some error in Credit Card"
      this.setState({ pleaseWait: false, isDisable: false, error });
    }  
  };

  checkForAllCheck = () => {
    let allOk = true;
    this.state.selectedInvoices.map((inv) => {
      if (!inv.showCheck) {
        allOk = false;
      }
      return null
    });
    return (
      this.state.selectedInvoices.length === this.state.receivePayment.length &&
      this.state.selectedInvoices.length > 0 &&
      allOk
    );
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  fetchCustomers = async () => {
    const { companySearchText } = this.state;
    const {
      value: { data },
    } = await this.props.fetchCustomerOpenBalance();
    const customers = data.customers;
    if(companySearchText.length >= 3){
     const searchData = customers.filter(e => e.companyName.toLowerCase().includes(companySearchText.toLowerCase()))
     this.setState({customers: searchData})
    }else{
      this.setState({customers: customers})
    }
  };


  handleCompanySearch = event => {
    this.setState({
      companySearchText: event.target.value,
    });
    clearTimeout(this.debouncedTimeout);
    this.debouncedTimeout = setTimeout(() => {
      this.fetchCustomers();
    }, 1500);
  };

  assignCustomer = () => {
    if(this.state.selectedCustomer){
      this.setState(
        {
          isCompany: false,
          visible: false,
        },
      );
      this.props.history.push(
        `/posted/receive-payment/${this.state.selectedCustomer._id}`
      );
      const url = window.location.pathname;
      const urlArray = url.split("/");
      this.fetchInvoices(urlArray[1], urlArray[3]);
      this.fetchCustomers()
    }
  };
  
  selectCustomer(customer) {
      if (this.state.selectedCustomer && this.state.selectedCustomer._id === customer._id) {
        this.setState({ selectedCustomer: null });
      } else {
        this.setState({ selectedCustomer: customer });
      }
  }
  
  showDrawer = () => {
    this.setState({ visible: true });
  };

  fetchCustomerDetail = async () => {
    const {
      value: {
        data: { customer },
      },
    } = await this.props.fetchCustomer(this.state.customerData?.customerId);
    this.setState({ customerData: {...customer} });
  };

    // assign card payabli
    assignCardData = async (cardData) => {
      this.setState({ payabliInfo: cardData, isAddNewCard: false });
      const payload = {
        _id: this.state.customerData._id,
        payabliCustomer: cardData,
      };
      await this.props.updateCustomer(payload);
      this.setState({ cardAdded: false });
      this.getCustomerPayment(cardData?.customerId);
    };
    assignCard = async (cardId, paymethod) => {
    const err = this.state.error;
    err.cardNumber=""
    err.responseError=""

      this.setState({ cardId: cardId, payMethod: paymethod,err , isDisable:false });
    };

    getCustomerPayment = async (customerId) => {
      this.setState({cardSpinner:true})
      const payload = {
        customerId: customerId
      }
      const { value: { data } } = await this.props.getPayabliCustomerData(payload);
      this.setState({cardSpinner:false})
      this.setState({ customerPayabliData: data, payabliCustomerMethod: data })
    }
    insertSlash(val) {
      return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
    }

  render() {
    return (
      <div className="receive__payment-wrapper">
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">&nbsp;</div>
          <div className="head-section-cta">
            <span
              className="btn btn-link btn-back"
              onClick={() => {
                this.props.history.push("/billing/posted-transactions");
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <div className="receive__payment-container">
          <div className="credit-card">
            <div className="card-header">
              <h5 className="card-title">
                Receive Payment
              </h5>
              <div
                   className="reading__select-list mt-3 pl-0 col-lg-6"
                     onClick={() =>
                         this.setState({
                                isCompany: true,
                              })
                         }
                          >
                            <div className="reading__select-item" onClick={this.showDrawer}>
                              <h6 className="reading__select-title">Select Company </h6>
                              <p className="reading__select-sub-title">
                                <input
                                  name="customer"
                                  readOnly
                                  placeholder="Select"
                                  className="reading__select-input"
                                  value={this.state.receivePayment[0]?.customer?.displayName}
                                  type="text"
                                />
                              </p>
                            </div>
                            {/* <ErrorMessage component="span" name="customer" className="invalid-feedback d-block" /> */}
                          </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5">
                  <div className="receive__payment-select-option">
                    <div className="form-group material-textfield">
                      <div className="reading__select-list">
                        <div className="reading__select-item">
                          <h6 className="reading__select-title">
                            Select Date <span>*</span>
                          </h6>
                          <DatePicker
                            name="from"
                            selected={moment(
                              this.state.date,
                              "MMM DD, YYYY"
                            ).toDate()}
                            onChange={(date) => {
                              this.setState({ date: date });
                            }}
                            className="reading__select-input"
                            // placeholder="10/01/20"
                            required
                            autoComplete="off"
                          />
                          {/* <p onClick={()=>{this.setState({isDatePickerOpen:true})}} className='reading__select-sub-title'>
                          <input
                            name='customer'
                            readOnly
                            placeholder='Select'
                            className='reading__select-input'
                            value={customer?.companyName}
                            type='text'
                          />{" "} 
                          {moment(this.state.date).format('L')}
                  </p> */}
                        </div>
                      </div>

                      {/* {this.state.isDatePickerOpen && 
                            <DatePicker
                          name="from"
                          selected={moment(this.state.date, "MMM DD, YYYY").toDate()}
                          // onChange={handleFromChange}
                          className="form-control material-textfield-input"
                          placeholder="10/01/20"
                          required
                          autoComplete="off"
                        />
                      } */}
                      {/* <select
                      onChange={()=>{this.setState({isDatePickerOpen:true})}}
                        className="form-control custom-select w-150 material-textfield-input pr-22"
                      >
                        <option value="">{ moment(this.state.date).format('L')}</option>
                      </select> */}

                      {/* <label className="material-textfield-label">Select Date</label> */}
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        value={this.state.paymentMethodType.paymentType}
                        onChange={(e) => {
                          let paymentMethodType = this.state.paymentMethodType;
                          paymentMethodType.paymentType = e.target.value;
                          paymentMethodType.PaymentMethodTypeStore.type = e.target.value;
                          paymentMethodType.PaymentMethodTypeStore.value = "";
                          this.setState({
                            error:{},
                            paymentMethodType: paymentMethodType,
                            reRender: !this.state.reRender,
                            creditMemoNumber: "",
                            isDisable: false,
                            selectedInvoices:[],
                            cardId:""
                          },()=>{this.clearPayment()});
                          if(e.target.value === "credit-card")this.getCustomerPayment(this.state.customerData?.payabliCustomer?.customerId)
                        }}
                        className="form-control custom-select w-150 material-textfield-input pr-22"
                      >
                        <option value="cash">Cash</option>
                        <option value="check">Check</option>
                        <option value="wire">Wire</option>
                        <option value="credit-card">Card</option>
                        {this.state.customerCredits.length > 0 && (
                          <option value="credit-memo">Credit Memo</option>
                        )}
                      </select>
                      {
                        <p className="invalid-feedback d-block">
                          {this.state.error?.paymentType}
                        </p>
                      }
                      <label className="material-textfield-label">
                        Payment Method <span>*</span>
                      </label>
                      <div className="available-credit-amount">Available Credit: <span>${this.numberWithCommas(Number(this.state.creditMemoSum))}</span></div>
                    </div>
                    <div>                   
               
                </div>
                    {this.state.paymentMethodType?.paymentType === "cash" && (
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          name="firstName"
                          onChange={(e) => {
                            let paymentMethodType =
                              this.state.paymentMethodType;
                            paymentMethodType.PaymentMethodTypeStore.type =
                              "referenceNumber";
                            paymentMethodType.PaymentMethodTypeStore.value =
                              e.target.value;
                            this.setState({
                              paymentMethodType: paymentMethodType,
                              reRender: !this.state.reRender,
                            });
                          }}
                          className="form-control material-textfield-input"
                          required
                        />
                        {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                        <label className="material-textfield-label">
                          Reference Number
                        </label>
                      </div>
                    )}
                    {this.state.paymentMethodType?.paymentType === "check" && (
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          name="firstName"
                          onChange={(e) => {
                            const error = {};
                            error.checkNumber = "";
                            let paymentMethodType =
                              this.state.paymentMethodType;
                            paymentMethodType.PaymentMethodTypeStore.type =
                              "checkNumber";
                            paymentMethodType.PaymentMethodTypeStore.value =
                              e.target.value;
                            this.setState({
                              paymentMethodType: paymentMethodType,
                              reRender: !this.state.reRender,
                              error: error,
                              isDisable: false,
                            });
                          }}
                          className="form-control material-textfield-input"
                          required
                        />
                        {
                          <p1 className="invalid-feedback d-block">
                            {this.state.error?.checkNumber}
                          </p1>
                        }
                        {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                        <label className="material-textfield-label">
                          Check Number <span>*</span>
                        </label>
                      </div>
                    )}
                    {this.state.paymentMethodType?.paymentType === "wire" && (
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          name="firstName"
                          onChange={(e) => {
                            const error = {};
                            error.transationId = "";
                            let paymentMethodType =
                              this.state.paymentMethodType;
                            paymentMethodType.PaymentMethodTypeStore.type =
                              "transactionId";
                            paymentMethodType.PaymentMethodTypeStore.value =
                              e.target.value;
                            this.setState({
                              paymentMethodType: paymentMethodType,
                              reRender: !this.state.reRender,
                              error: error,
                              isDisable: false,
                            });
                          }}
                          className="form-control material-textfield-input"
                          required
                        />
                        {
                          <p1 className="invalid-feedback d-block">
                            {this.state.error?.transationId}
                          </p1>
                        }
                        {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                        <label className="material-textfield-label">
                          Transaction ID <span>*</span>
                        </label>
                      </div>
                    )}
                    {this.state.paymentMethodType?.paymentType ===
                      "credit-memo" && (
                        <div className="form-group material-textfield">
                          <select
                            value={this.state.creditMemoNumber}
                            onChange={(e) => {
                              const error = this.state.error;
                              error.creditMemoNumber = "";
                              this.state.customerCredits.length > 0 &&
                                this.state.customerCredits.map((item) => {
                                  if (e.target.value === item.creditMemoNumber) {
                                    this.setState({
                                      totalCustomerCreditAmount:
                                        item?.receiveCreditAmount,
                                    });
                                  }
                                  return null;
                                });
                              let paymentMethodType =
                                this.state.paymentMethodType;
                              paymentMethodType.PaymentMethodTypeStore.type =
                                "credit-memo";
                              paymentMethodType.PaymentMethodTypeStore.value =
                                e.target.value;
                              this.setState({
                                paymentMethodType: paymentMethodType,
                                creditMemoNumber: e.target.value,
                                error: error,
                                isDisable: false,
                              });
                            }}
                            className="form-control custom-select w-150 material-textfield-input pr-22"
                          >
                            <option value="">Select</option>
                            {this.state.customerCredits.length > 0 &&
                              this.state.customerCredits.map((item) => (
                                <option value={item?.creditMemoNumber}>{` ${item?.creditMemoNumber
                                  }  - $${this.numberWithCommas(
                                    Number(item?.receiveCreditAmount)
                                  )}`}</option>
                              ))}
                          </select>
                          {
                            <p1 className="invalid-feedback d-block">
                              {this.state.error?.creditMemoNumber}
                            </p1>
                          }
                          {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                          <label className="material-textfield-label">
                            Credit Memo <span>*</span>
                          </label>
                        </div>
                      )}
                    {this.state.paymentMethodType?.paymentType === "credit-card" && (
                      <div>
                        {/* <div className="form-group material-textfield">
                        <input
                          type="text"
                          name="firstName"
                          onChange={(e) => {
                            const error = {};
                            error.cardNumber = "";
                            let paymentMethodType =
                              this.state.paymentMethodType;
                            paymentMethodType.PaymentMethodTypeStore.type =
                              "creditCard";
                            paymentMethodType.PaymentMethodTypeStore.value =
                              e.target.value;
                            this.setState({
                              paymentMethodType: paymentMethodType,
                              reRender: !this.state.reRender,
                              error: error,
                              isDisable: false,
                            });
                          }}
                          className="form-control material-textfield-input"
                          required
                        />
                        {
                          <p1 className="invalid-feedback d-block">
                            {this.state.error?.cardNumber}
                          </p1>
                        }
                        <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          Credit Card <span>*</span>
                        </label>
                      </div> */}
                        <>
                          {this.state.paybliEnable ? (
                            <div className="form-group material-textfield">
                              {this.state.cardSpinner ? <div className="form-control d-flex align-items-center justify-content-center custom-select w-150 material-textfield-input pr-22">
                              <Spin indicator={antIcon} spinning={true} />
                              </div>:
                              <select
                                className="form-control custom-select w-150 material-textfield-input pr-22"
                                required
                                onChange={(e) => {
                                  const paymentMethod =
                                    e.target.value.split("/");
                                  this.assignCard(paymentMethod[0], paymentMethod[1])
                                }}
                              >
                                <option value={" "} key={""}>
                                  Select Credit Card
                                </option>
                                {this.state.customerPayabliData
                                  ?.StoredMethods.length > 0 &&
                                  this.state.customerPayabliData?.StoredMethods.map(
                                    (item) => {
                                      return (
                                        <option
                                          value={
                                            item.IdPmethod +
                                            "/payabli"
                                          }
                                          key={item.IdPmethod}
                                        >
                                          {item.Descriptor}{" "}
                                          {item.MaskedAccount}
                                          &nbsp;
                                          {this.insertSlash(
                                            item.ExpDate
                                          )}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>}
                              {
                                <p1 className="invalid-feedback d-block">
                                  {this.state.error?.cardNumber}
                                </p1>
                              }
                              <label className={`${this.state.cardSpinner ? "loader__label":""} material-textfield-label`}>Select Credit Card </label>
                            </div>
                          ) : (
                            <div className="form-group material-textfield">
                              { this.state.cardSpinner ? <div className="form-control custom-select w-150 material-textfield-input pr-22">
                              <Spin indicator={antIcon} spinning={true} />
                              </div>:
                              <select
                                className="form-control custom-select w-150 material-textfield-input pr-22"
                                required
                                onChange={(e) => {
                                  const paymentMethod =
                                    e.target.value.split("/");
                                  this.assignCard(paymentMethod[0], paymentMethod[1])
                                }}
                              >
                                <option value={" "} key={""}>
                                  Select Credit Card
                                </option>
                                {_.get(
                                  this.state.customerData,
                                  "paymentmethods",
                                  []
                                ).map(
                                  (payment_method_details) => {
                                    const {
                                      _id = "",
                                      details = {},
                                    } = payment_method_details;
                                    return (
                                      <option
                                        value={
                                          _id + "/payengine"
                                        }
                                        key={_id}
                                      >
                                        {details.brand} ****
                                        {details.last4digits}
                                      </option>
                                    );
                                  }
                                )}
                              </select>}
                              {
                                <p1 className="invalid-feedback d-block">
                                  {this.state.error?.cardNumber}
                                </p1>
                              }
                              <label className={`${this.state.cardSpinner ? "loader__label":""} material-textfield-label`}>Select Credit Card </label>
                            </div>
                          )}
                        </>
                        <span onClick={() => {
                          if(this.state.isAddNewCard){
                            this.payabliRef.current._initPayabli();
                          }
                          this.setState({ isAddNewCard: true }, () => {
                            this.forceUpdate()
                          });
                        }} className="add__newcredit-card">
                          Add a new Credit Card
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.paymentMethodType.paymentType !== "credit-memo" ? <div className="outstanding__transactions">
                <div className="outstanding__amount-header">
                  <h2 className="title">Outstanding Transactions</h2>
                  {/* <h2 className="title">Amount Receieved: ${this.state.totalAmmount}</h2> */}
                  <div
                    style={{ width: this.inputRef?.current?.clientWidth }}
                    className="input-active-global material-textfield"
                  >
                    {/* <input
                      type="number"
                      onChange={(e) => {
                        this.handleTotalChanged(e.target.value);
                      }}
                      value={this.state.totalAmmount}
                      className="form-control material-textfield-input"
                    ></input> */}
                    <MoneyInput
                      onChange={(e) => {
                        this.handleTotalChanged(e);
                      }}
                      value={this.state.totalAmmount}
                    />
                    {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                    <label className="material-textfield-label">
                      Amount Received
                    </label>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th className="first-child">
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            Invoice #
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              onChange={this.handleChangedCheck}
                              name="total"
                              checked={this.checkForAllCheck()}
                            />
                            <span className="checkmark"></span>
                          </label>
                          {/* Invoice # */}
                        </th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <th>Original Amount</th>
                        <th>Open Balance</th>
                        <th>Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.receivePayment.map((v, index) => {
                        const items = {
                          ...v,
                          amountToShow:
                            this.state.paymentMethodType.paymentType ===
                              "credit-memo"
                              ? v.payment
                              : v.amountToShow,
                        };
                        const found = this.state.selectedInvoices.filter(
                          (inv) => inv?._id === items?._id
                        );
                        return (
                          <>
                            <tr>
                              <td className="first-child">
                                <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    onChange={(e) =>
                                      this.handleChangedCheck(e, items, true, index)
                                    }
                                    checked={
                                      found.length > 0 && found[0].showCheck
                                    }
                                  />
                                  <span className="checkmark custom-m"></span>
                                </label>
                                {items?.invoiceId}
                              </td>
                              <td>
                                {moment(items?.invoiceDate)?.tz(this.state?.tz)?.format("L") +
                                  " " +
                                  moment(items?.invoiceDate)?.tz(this.state?.tz)?.format("LT")}
                              </td>
                              <td>{moment(items?.dueDate)?.tz(this.state?.tz).format("L")}</td>
                              <td>
                                ${this.numberWithCommas(Number(items?.total))}
                              </td>
                              <td>
                                $
                                {this.numberWithCommas(Number(items?.balacnce))}
                              </td>
                              <td className="last-child">
                                <div className="input-active-global material-textfield">
                                  {/* <NumberFormat
                                  name="amount"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  isAllowed={(values) => {
                                    const { floatValue, formattedValue } = values;
                                    return formattedValue === 0 || floatValue <= Number(items?.balacnce);
                                  }}
                                  onValueChange={(_values) => {
                                    this.handlePaymnet(Number(_values.formattedValue), index)
                                  }}
                                  value={items?.payment}
                                /> */}
                                  {/* <input
                                    type="number"
                                    onChange={(e) => {
                                      // var value = parseFloat(e.target.value);
                                      found.length < 1 &&  this.handleChangedCheck({target:{checked:true}},items)
                                      this.handlePaymnet(
                                        e.target.value,
                                        index,
                                        items?.balacnce,
                                        items
                                      );
                                    }}
                                    
                                    value={items?.amountToShow === 0 ? "" : items.amountToShow.toString().includes(".") ? Number(items?.amountToShow).toFixed(2) : items.amountToShow}
                                    className="form-control material-textfield-input"
                                    ref={this.inputRef}
                                  ></input> */}
                                  <MoneyInput
                                    value={
                                      items?.amountToShow === 0
                                        ? ""
                                        : items.amountToShow
                                    }
                                    onChange={(e) => {
                                      found.length < 1 &&
                                        this.handleChangedCheck(
                                          { target: { checked: true } },
                                          items
                                        );
                                      this.handlePaymnet(
                                        e,
                                        index,
                                        items?.balacnce,
                                        items
                                      );
                                    }}
                                    inputRef={this.inputRef}
                                  />
                                  {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                                  <label className="material-textfield-label">
                                    Amount
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div> : <div className="outstanding__transactions">
                <div className="outstanding__amount-header">
                  <h2 className="title">Outstanding Transactions</h2>
                  <h2 className="title">Amount Received: ${this.numberWithCommas(this.state.amountReceive ?? 0)}</h2>
                </div>
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th className="first-child">
                          {/* <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            Invoice #
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                            />
                            <span className="checkmark"></span>
                          </label> */}
                          Invoice #
                        </th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <th>Original Amount</th>
                        <th>Open Balance</th>
                        <th>Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.receivePayment.map((items, index) =>
                        <>
                          <tr>
                            <td className="first-child">
                              {/* <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                               
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                />
                                <span className="checkmark"></span>

                              </label> */}
                              {items?.invoiceId}
                            </td>
                            <td>{moment(items?.invoiceDate)?.tz(this.state?.tz)?.format('L') + " " + moment(items?.invoiceDate)?.tz(this.state?.tz).format('LT')}</td>
                            <td>{moment(items?.dueDate)?.tz(this.state?.tz).format('L')}</td>
                            <td>${this.numberWithCommas(Number(items?.total))}</td>
                            <td>${this.numberWithCommas(Number(items?.balacnce))}</td>
                            <td className="last-child">
                              <div className="input-active-global material-textfield">
                                {/* <NumberFormat
                                  name="amount"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  isAllowed={(values) => {
                                    const { floatValue, formattedValue } = values;
                                    return formattedValue === 0 || floatValue <= Number(items?.balacnce);
                                  }}
                                  onValueChange={(_values) => {
                                    this.handlePaymnet(Number(_values.formattedValue), index)
                                  }}
                                  value={items?.payment}
                                /> */}
                                <NumberFormat 
                                   thousandSeparator={true}
                                  decimalScale={2}
                                  // fixedDecimalScale={true}
                                  displayType={'input'}
                                  prefix={'$'}
                                  value= {items?.payment}
                                  type='text'
                                  className="form-control material-textfield-input"
                                  onValueChange={(values) => {
                                    const formattedValue = values.value;
                                      this.handlePaymnetForCredit(formattedValue, index, items?.balacnce) 
                                    
                                  }}
                                  
                                />
                                {/* <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" /> */}
                                <label className="material-textfield-label">
                                  Amount
                                </label>
                              </div>
                            </td>

                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              }
            </div>
          </div>
          <div className="total__amount">
            <ul className="list">
              <li className="items">
                <h3 className="title">Amount to Apply</h3>
                <span>
                  $
                  {this.state.paymentMethodType.paymentType === "credit-memo" ? this.numberWithCommas(this.state.amountReceive ?? 0) : this.numberWithCommas(!isNaN(Number(this.state.amountToApply)) ? Number(this.state.amountToApply) : 0)}
                </span>
                {/* <span>${this.numberWithCommas(this.state.amountReceive)}</span> */}
              </li>
              {this.state.user?.company?.chargeStripeCardFees && this.state.paymentMethodType?.paymentType === "credit-card" ? <li className="items">
                <h3 className="title">Handling Fee</h3>
                <span>
                  $
                  {this.numberWithCommas(Number(this.calcFee(this.state.totalAmmount, "USD")) ?? 0)}
                </span>
              </li> : ""}
              <li className="items">
                <h3 className="title">Amount to Credit</h3>
                <span>
                  $
                  {this.numberWithCommas(
                    this.round2decimal(
                      Number(
                        !isNaN(this.state.totalCreditAmount)
                          ? this.state.totalCreditAmount
                          : 0
                      )
                    )
                  )}
                </span>
              </li>
              {/* <li className="items">
                <h3 className="title">Difference</h3>
                <span className="amount">${this.numberWithCommas(this.round2decimal(Number(this.state.amountToApply) - Number(this.state.amountReceive)))}</span>
              </li> */}
            </ul>
            <br />
            <button
              onClick={() => {
                this.clearPayment();
              }}
              className="btn btn-dark d-block ml-auto"
            >
              Clear Payment
            </button>
          </div>
          <div className="memo__attachments">
            <div className="memo__attachments-inner">
              <div className="memo__attachments-col">
                <label>Memo</label>
                <textarea
                  value={this.state.memo}
                  onChange={(e) => {
                    this.setState({ memo: e.target.value });
                  }}
                  className="textarea"
                  placeholder="Enter Note"
                />
              </div>
              <div className="memo__attachments-col">
                <label>Attachments</label>
                <div className="upload__file">
                  <label for="formFile" className="form-label">
                    Drag and Drop <br />
                    or
                  </label>
                  <input
                    type="file"
                    id="myFile"
                    onChange={(e) => {
                      this.onChangeFile(e);
                    }}
                    name="filename"
                  />
                  <button className="btn btn-dark">Upload</button>
                </div>
                <div className="ttachments__list">
                  {this.state.showAttachments.length > 0 &&
                    this.state.showAttachments.map((item) => (
                      <>
                        <div className="items">
                          <img src={item} alt=""></img>
                        </div>
                        {/* <div className="items"></div> */}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {this.state.error?.payment !== "" &&
            <>
              <p1 className="invalid-feedback d-block">
                {this.state.error?.payment}
              </p1>
              <br />
            </>
          }
          {this.state.error?.responseError !== "" &&
            <>
              <p1 className="invalid-feedback d-block">
                {this.state.error?.responseError}
              </p1>
              <br />
            </>
          }

          <button
            disabled={this.state.isDisable || this.state.pleaseWait}
            onClick={() => {
              this.validation();
            }}
            className="btn btn-dark btn-lg w-100 create__order-btn mb-5"
          >
            {this.state.pleaseWait ? "Please Wait ..." : "Save"}
          </button>
        </div>

        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="select__driver-drawer creat__order-drawer"
        >
          {this.state.isCompany ? (
            <div className="creat__order-drawer-inner">
              <div className="head-section-header d-flex align-items-center justify-content-between">
                <div className="head-section-title">Select Company</div>
                <div className="head-section-cta">
                  <div onClick={this.onClose} className="btn btn-link btn-back mobpad">
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield col-lg-12 pl-0">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleCompanySearch}
                  value={this.state.companySearchText}
                  required
                />
                <label className="material-textfield-label">Search Company</label>
              </div>
              <ul className="select_driver-list box-shadow">
                {this.state.customers.map(customer => {
                  return (
                    <li
                      className={
                        String(this.state.selectedCustomer && this.state.selectedCustomer._id) === String(customer._id)
                          ? "active"
                          : ""
                      }
                      key={customer._id}
                    >
                      <div className="filter_list-inner d-flex align-items-center">
                        <p className="driver__name">{_.get(customer, "displayName", "")}</p>
                      </div>
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          onChange={() => this.selectCustomer(customer)}
                          checked={
                            this.state.selectedCustomer && String(this.state.selectedCustomer._id) === String(customer._id)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  );
                })}
              </ul>
              <div className="mt-20 mb-20">
                {this.state.customers.length > 0 ? (
                  <button
                    className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                    onClick={() => this.assignCustomer()}
                    disabled={!this.state.selectedCustomer ? true : false}
                  >
                    Assign Company
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Drawer>
        {this.state.isAddNewCard && this.state.paybliEnable ? (
          <AddCardComponent
            paybliDetails={this.state.paybliDetails}
            customerData={this.state.customerData}
            assignCardData={this.assignCardData}
            payabliRef={this.payabliRef}
          />
        ) : this.state.isAddNewCard && (
          <>
            <PECardModal
              addCard={this.props.addCard}
              isCardModalOpen={this.state.isAddNewCard}
              closeModal={() => this.setState({ isAddNewCard: false })}
              fetchOrder={""}
              fetchCustomerDetail={this.fetchCustomerDetail}
              customerId={this.state.customerData._id}
            />
          </>
        )}
      </div>
    );
  }
}
