import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import { FilterIcon, CloseIcon, ExportIcon, DateIcon } from "../../components/icons";
import CloseWhite from "./../../images/close_white.svg";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { Drawer } from "antd";
import "./styles.scss";
import moment from "moment-timezone";
import ReactExport from "react-data-export";
import { numberFormat, numberFormatLbs ,numberFormatWidthTypeNumber } from "../../constants/common";
import { Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import QueuedForSignature from "./partials/driver-signature";
import CompletedOrders from "./partials/completed-orders";
import PendingPayments from "./partials/peding-payments";
import FailedPayments from "./partials/failed-payments";
import { DebounceInput } from "react-debounce-input";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  startOfDay,
} from "date-fns";
import SearchCheckboxComponent from "../Common/SearchCheckbox";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const searchLimit = 100;
const defaultLimit = 250;
export default class OrdersComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    // const activeTab = props.location.pathname === "/queued-for-drivers-signature" ? "signature" : "completed";
    const activeTab =
  props.location.pathname === "/queued-for-drivers-signature" ? "signature" :
  props.location.pathname === "/completed-orders" ? "completed" :
  props.location.pathname === "/failed-payments" ? "failed" :
  "pending";
    this.state = {
      searchStartsWith: true,
      toggleMenu: false,
      orders: [],
      from: "",
      to: "",
      users: [],
      drivers: [],
      customers: [],
      isVoided: false,
      customerSearchText: "",
      userSearchText: "",
      driverSearchText: "",
      modalIsOpen: false,
      typingTimeout: 0,
      filterCustomers: [],
      filterDrivers: [],
      filterUsers: [],
      dataSet: [],
      sortOrder: { ticketNumber: -1 },
      activeTab,
      progressBar: false,
      companyFilter: false,
      userFilter: false,
      isFailedPayment: false,
      totalFailedItems: 0,
      driverFilter: false,
      spinning: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: Number(sessionStorage.getItem("current-page-complete-orders")) ? Number(sessionStorage.getItem("current-page-complete-orders"))  : 1,
      mainSearchText: "",
      isPaymentLater: false,
      startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
      endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
      DateRangePickerDroDown: false,
      date: {
        startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
        endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
        key: 'selection'
      },
      tz
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openFilterList = this.openFilterList.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    if (this.props.location.pathname === "/queued-for-drivers-signature") {
      this.setPageTitle("signature");
    } else {
      this.setPageTitle("completed");
    }
    if (this.props.location.pathname === "/pending-payments") {
      this.setAsyncState({isPaymentLater: true, activeTab: 'pending-payments'});
    }
    if (this.props.location.pathname === "/failed-payments") {
      this.setPageTitle("failed");
      this.setAsyncState({isPaymentLater: true , isFailedPayment : true});
    }
    const {
      history: {
        location: { state }
      }
    } = this.props;
    if (state) {
      const { from, to } = state;
      await this.setAsyncState({
        from: moment(from, "MMM DD, YYYY").toDate(),
        to: moment(to, "MMM DD, YYYY").toDate()
      });
    }
    const orderFilter = JSON.parse(localStorage.getItem("orderFilter"));
    if (orderFilter && !state) {
      const { from, to } = orderFilter;
      await this.setAsyncState({
        from: moment(from).isValid() ? moment(from).toDate() : "",
        to: moment(to).isValid() ? moment(to).toDate() : "",
        filterCustomers: _.get(orderFilter, "filterCustomers", []),
        filterDrivers: _.get(orderFilter, "filterDrivers", []),
        filterUsers: _.get(orderFilter, "filterUsers", []),
        sortOrder: _.get(orderFilter, "sortOrder", { ticketNumber: -1 })
      });
    }
    const billingData = JSON.parse(localStorage.getItem("OrderDate"))
    const startDate = startOfDay(new Date(JSON.parse(localStorage.getItem("OrderDate"))?.startDate ?? new Date()))
    const endDate = endOfDay(new Date(JSON.parse(localStorage.getItem("OrderDate"))?.endDate ?? new Date()))
    const date = { startDate: startDate, endDate: endDate, key: 'selection' }
    billingData && this.setState({ startDate: startDate, endDate: endDate, date: date })
    window.addEventListener('click', this.handleClickOutside);
    this.fetchOrders();
    this.fetchCustomersList(defaultLimit);
    this.fetchDriversList(defaultLimit);
    this.fetchUsersList();
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  }

  fetchOrders = async () => {
    const { fetchOrderList } = this.props;
    const {
      from,
      to,
      activeTab,
      filterCustomers,
      filterDrivers,
      filterUsers,
      sortOrder,
      skip,
      limit,
      currentPage,
      mainSearchText,
      startDate, 
      endDate
    } = this.state;
    // this.setState({ spinning: true });
    const {
      value: {
        data: { orders, ordersCount , failedOrdersCount}
      }
    } = await fetchOrderList({
      // from: moment(startDate, "MMM DD, YYYY")
      //   .startOf("day")
      //   .toISOString(),
      // to: moment(endDate, "MMM DD, YYYY")
      //   .endOf("day")
      //   .toISOString(),

      from: moment(startDate)?.startOf("day")?.toISOString(),
      to: moment(endDate)?.endOf("day")?.toISOString(),
      type: activeTab,
      filterCustomers: filterCustomers.map(c => ({ ...c, companyName: escape(c.companyName) })),
      filterDrivers,
      filterUsers,
      sortOrder,
      skip,
      limit,
      isPaymentLater: this.state.isPaymentLater,
      isFailedPayment: this.state.isFailedPayment,
      isVoided:this.state.isVoided,
      mainSearchText: escape(mainSearchText),
      searchStartsWith: this.state.searchStartsWith
    });
    this.setState({ orders, totalItems: ordersCount, spinning: false , totalFailedItems : failedOrdersCount });
    localStorage.setItem(
      "orderFilter",
      JSON.stringify({ from, to, filterCustomers, filterDrivers, filterUsers, sortOrder, skip, currentPage })
    );
  };

  fetchCustomersList = async (limit) => {
    const { fetchCustomers } = this.props;
    const { customerSearchText } = this.state;
    const {
      value: {
        data: { customers }
      }
    } = await fetchCustomers({ searchText: customerSearchText, status: 1, sortOrder: { companyName: 1 }, limit });
    this.setState({ customers });
  };

  fetchUsersList = async () => {
    const { fetchCompanyUsers } = this.props;
    const { userSearchText } = this.state;
    const {
      value: {
        data: { users }
      }
    } = await fetchCompanyUsers({ searchText: userSearchText });
    this.setState({ users });
  };

  fetchDriversList = async (limit) => {
    const { fetchCompanyDrivers } = this.props;
    const { driverSearchText } = this.state;
    const {
      value: { data }
    } = await fetchCompanyDrivers({ searchText: driverSearchText, limit });
    this.setState({ drivers: data });
  };

  handleFromChange = date => {
    this.setState({ from: date, skip: 0, currentPage: 1 }, () => {
      this.fetchOrders();
    });
  };

  handleToChange = date => {
    this.setState({ to: date, skip: 0, currentPage: 1 }, () => {
      this.fetchOrders();
    });
  };

  handleCustomerSearch = event => {
    this.setState({
      customerSearchText: event.target.value.trimStart()
    });
    this.fetchCustomersList(searchLimit);
  };

  handleDriverSearch = event => {
    this.setState({
      driverSearchText: event.target.value.trimStart()   
    });
    this.fetchDriversList(searchLimit);
  }; 

  handleUserSearch = event => {
    this.setState({
      userSearchText: event.target.value,
    });
    this.fetchUsersList();
  };

  handleMainSearchText(event) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      skip: 0,
      currentPage: 1,
      mainSearchText: event.target.value.trimStart(),
      typingTimeout: setTimeout(() => {
        (event.target.value.length === 0 || this.state.mainSearchText.trimStart()!== "") && this.fetchOrders();
      }, 500)
    });
  }

  setPageTitle = tab => {
    if (tab === "signature") {
      document.title = "Queued for Drivers Signature | Weighworks";
    } else {
      document.title = "Completed Orders | Weighworks";
    }
  };

  onClickTab(activeTab, url) {
    const { history } = this.props;
    this.setState({ activeTab, skip: 0, isVoided: false, currentPage: 1 }, () => {
      this.setPageTitle(activeTab);
      this.fetchOrders();
      history.push(url);
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  handleVoidedFilter = () => {
    this.setState(
      prevState => ({ isVoided: !prevState.isVoided }),
      () => {
        this.fetchOrders();
      }
    );
  };
  openFilterList(e) {
    this.setState({
      [e.target.name]: e.target.checked
    });
    switch (e.target.name) {
      case "dateFilter":
        if (!e.target.checked) {
          this.setState(
            {
              from: "",
              to: ""
            },
            () => {
              this.fetchOrders();
            }
          );
        }
        break;
      case "userFilter":
        if (!e.target.checked) {
          this.setState(
            {
              filterUsers: []
            },
            () => {
              this.fetchOrders();
            }
          );
        }
        break;
      case "driverFilter":
        if (!e.target.checked) {
          this.setState(
            {
              filterDrivers: []
            },
            () => {
              this.fetchOrders();
            }
          );
        }
        break;
      case "companyFilter":
        if (!e.target.checked) {
          this.setState(
            {
              filterCustomers: []
            },
            () => {
              this.fetchOrders();
            }
          );
        }
        break;
      default:
        break;
    }
  }

  selectCustomer(customer) {
    const customers = _.cloneDeep(this.state.filterCustomers);
    const index = _.findIndex(customers, function(c) {
      return String(c._id) === String(customer._id);
    });
    if (index === -1) {
      customers.push({
        _id: String(customer._id),
        companyName: customer.companyName
      });
    } else {
      customers.splice(index, 1);
    }
    const companyFilter = customers.length > 0;
    this.setState({ filterCustomers: customers, companyFilter, skip: 0, currentPage: 1 }, () => {
      this.fetchOrders();
    });
  }

  selectDate = () => {
    this.setState({ from: "", to: "" }, () => {
      this.fetchOrders();
    });
  };

  selectUser(user) {
    const users = _.cloneDeep(this.state.filterUsers);
    const index = _.findIndex(users, function(u) {
      return String(u._id) === String(user._id);
    });
    if (index === -1) {
      users.push({
        _id: String(user._id),
        name: `${user.firstName} ${user.lastName}`
      });
    } else {
      users.splice(index, 1);
    }
    const userFilter = users.length > 0;
    this.setState({ filterUsers: users, userFilter, skip: 0, currentPage: 1 }, () => {
      this.fetchOrders();
    });
  }

  selectDriver(driver) {
    const drivers = _.cloneDeep(this.state.filterDrivers);
    const index = _.findIndex(drivers, function(d) {
      return String(d._id) === String(driver._id);
    });
    if (index === -1) {
      drivers.push({
        _id: String(driver._id),
        name: `${driver.firstName} ${driver.lastName}`
      });
    } else {
      drivers.splice(index, 1);
    }
    const driverFilter = drivers.length > 0;
    this.setState({ filterDrivers: drivers, driverFilter, skip: 0, currentPage: 1 }, () => {
      this.fetchOrders();
    });
  }

  exportOrder = async () => {
    const {activeTab, filterCustomers, filterDrivers, filterUsers, isVoided,mainSearchText, startDate, endDate } = this.state;
    this.setState({ progressBar: true });
    const {
      value: { data }
    } = await this.props.exportOrder({
      from: moment(startDate, "MMM DD, YYYY")
        .startOf("day")
        .toISOString(),
      to: moment(endDate, "MMM DD, YYYY")
        .endOf("day")
        .toISOString(),
      type: activeTab,
      filterCustomers,
      filterDrivers,
      filterUsers,
      isVoided,
      mainSearchText
    });
    await this.setAsyncState({ dataSet: data });
    setTimeout(() => {
      this.setState({ dataSet: [], progressBar: false });
    }, 500);
  };

  async setOrder(field) {
    const { sortOrder } = this.state;
    const sort = { [field]: sortOrder[field] === 1 ? -1 : 1 };
    await this.setAsyncState({ sortOrder: sort });
    this.fetchOrders();
  }

  clearFilter = () => {
    this.setState(
      {
        dateFilter: false,
        userFilter: false,
        driverFilter: false,
        companyFilter: false,
        isVoided: false,
        from: "",
        to: "",
        filterUsers: [],
        filterCustomers: [],
        filterDrivers: [],
        skip: 0,
        currentPage: 1
      },
      () => {
        this.fetchOrders();
      }
    );
  };
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchOrders();
    });
  };
  handleSelect = (ranges) => {
    ranges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: moment(ranges.selection.startDate)?.startOf("day").toDate(),
        endDate: moment(ranges.selection.endDate)?.endOf("day")?.toDate()
      }
    }
    const obj = {
      startDate: ranges.selection.startDate, endDate: ranges.selection.endDate
    }
    this.setState({
      date: ranges.selection,
      // isPaymentsDateRangePickerOpen: false
    });
    localStorage.setItem("OrderDate", JSON.stringify(obj));
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate },()=> this.fetchOrders())
  }
  handleClickOutside = (event) => {
    if (this.dateRangePickerRef && !this.dateRangePickerRef.contains(event.target)) {
      this.setState({ DateRangePickerDroDown: false });
    }
  };

  handleDataForCompleteorderExcel=(orderData)=>{
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    let ordersDataforexcel =[]
    for (let data of orderData){
      const excelRowData=[
        {value:data?.ticketNumber},
        {value :
          data?.isDeleted
            ? `${moment(data?.updatedAt).format("MM/DD/YY")} ${moment(data?.updatedAt).tz(tz)?.format("hh:mm a")}`
            : `${moment(data?.createdAt).format("MM/DD/YY")} ${moment(data?.createdAt).tz(tz)?.format("hh:mm a")}`
        },
        {value:data?.companyName},
        {value:data?.customerRefrence},
        {value:data?.netWeight || 0,style:{numFmt: "#,##;[Red]-#,##"}},
        {value:Number(data?.tonnage || 0),style:{numFmt: "#,##0.00;[Red]-#,##0.00"}},
        {value:data?.yardage || 0,style:{numFmt: "#,##0.00;[Red]-#,##0.00"}},
        {value:data?.totalAmount || 0,style:{numFmt: "$#,##0.00;[Red]-$#,##0.00"}},
        {value:(data?.paymentType === "" ? data?.paymentTerms : data?.paymentType === "account" ? "Account" : data?.paymentType.split(",").map(type => _.startCase(type.trim())).join(", "))},
        {value:_.startCase(data?.serviceLocation)}
      ]
    ordersDataforexcel.push(excelRowData)
    }
    const multiDataSet = [
      {
          columns: [
              {title: "Ticket", width: {wch: 40}},//pixels width 
              {title: "Date & Time", width: {wch: 40}},//char width 
              {title:"Company Name"},
              {title: "Cust Ref # "},
              {title:"Net Weight"},
              {title:"Tonnage"},
              {title:"Yardage"},
              {title:"Total Order Amount"},
              {title:"Payment Type"},
              {title:"Service Location"}
              
          ],
          data : ordersDataforexcel
      }
  ];
  return multiDataSet;
  }

  render() {
    const {
      location: { pathname },
      history,
      user
    } = this.props;
    // const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    const {
      orders,
      customers,
      users,
      drivers,
      from,
      to,
      filterCustomers,
      filterDrivers,
      filterUsers,
      dataSet,
      sortOrder,
      progressBar,
      spinning,
      isVoided,
      totalItems,
      totalFailedItems,
      mainSearchText
    } = this.state;
    let filterCount = 0;
    if (from && to) {
      filterCount += 1;
    }
    if (filterCustomers.length) {
      filterCount += 1;
    }
    if (filterDrivers.length) {
      filterCount += 1;
    }
    if (filterUsers.length) {
      filterCount += 1;
    }
    if (isVoided) {
      filterCount += 1;
    }
    return (
      <div>
        <div className="layout-has-sidebar">
          <TopNavigation
            {...this.props}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
          <main className="dashboard-layout-content complete__orders-wrapper">
            <Spin indicator={antIcon} spinning={spinning}>
              <div className="page-header" ref={ref => this.dateRangePickerRef = ref}>
              <div className="page-header-inner">
                <h2 className="heading-title">Orders</h2>
                </div>
                <div className="page-header-btn-grp">
                  {dataSet.length > 0 && pathname === "/completed-orders" && (
                    <ExcelFile hideElement filename="Completed-Orders">
                      <ExcelSheet dataSet={this.handleDataForCompleteorderExcel(dataSet)} name="Orders">
                        {/* <ExcelColumn label="Ticket" value="ticketNumber" />
                        <ExcelColumn
                          label={`Date & Time`}
                          value={col =>
                            col.isDeleted
                              ? `${moment(col.updatedAt).format("MM/DD/YY")} ${moment(col.updatedAt).tz(tz)?.format("hh:mm a")}`
                              : `${moment(col.createdAt).format("MM/DD/YY")} ${moment(col.createdAt).tz(tz)?.format("hh:mm a")}`
                          }
                        />
                        <ExcelColumn label="Company Name" value="companyName" />
                        <ExcelColumn label="Cust Ref # " value={col => col.customerRefrence}  />
                        <ExcelColumn label="Net Weight" value={col => numberFormatLbs(col.netWeight)} />
                        <ExcelColumn label="Tonnage" value={col => numberFormatWidthTypeNumber(col.tonnage)} />
                        <ExcelColumn label="Yardage" value={col => numberFormatWidthTypeNumber(col.yardage)} />
                        <ExcelColumn label="Total Order Amount" value={col => ('$' + numberFormat(col.totalAmount))} />
                        <ExcelColumn label="Payment Type"  value={col => _.startCase(col.paymentType === "" ? col.paymentTerms : col.paymentType === "account" ? "Account" : col.paymentType)} />
                      <ExcelColumn label="Service Location" value={col => _.startCase(col.serviceLocation)} /> */}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  {dataSet.length > 0 && pathname === "/pending-payments" && (
                    <ExcelFile hideElement filename="Pending-Payments">
                      <ExcelSheet data={dataSet} name="Orders">
                        <ExcelColumn label="Ticket" value="ticketNumber" />
                        <ExcelColumn
                          label={`Date & Time`}
                          value={col =>
                            `${moment(col.createdAt).format("MM/DD/YY")} ${moment(col.createdAt).format("hh:mm a")}`
                          }
                        />
                        <ExcelColumn label="Company Name" value="companyName" />
                        <ExcelColumn label="Cust Ref # " value={col => col.customerRefrence}  />
                        <ExcelColumn label="Net Weight" value={col => numberFormatLbs(col.netWeight)} />
                        <ExcelColumn label="Tonnage" value={col => numberFormatWidthTypeNumber(col.tonnage)} />
                        <ExcelColumn label="Yardage" value={col => numberFormatWidthTypeNumber(col.yardage)} />
                        <ExcelColumn label="Total Order Amount" value={col => numberFormat(col.totalAmount)} />
                        <ExcelColumn label="Service Location" value={col => _.startCase(col.serviceLocation)} />
                        {/* <ExcelColumn label="Payment Type" value={col => _.startCase(col.paymentType)} /> */}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  {dataSet.length > 0 && pathname === "/failed-payments" && (
                    <ExcelFile hideElement filename="failed-Payments">
                      <ExcelSheet data={dataSet} name="Orders">
                        <ExcelColumn label="Ticket" value="ticketNumber" />
                        <ExcelColumn
                          label={`Date & Time`}
                          value={col =>
                            `${moment(col.createdAt).format("MM/DD/YY")} ${moment(col.createdAt).format("hh:mm a")}`
                          }
                        />
                        <ExcelColumn label="Company Name" value="companyName" />
                        <ExcelColumn label="Cust Ref # " value={col => col.customerRefrence}  />
                        <ExcelColumn label="Net Weight" value={col => numberFormatLbs(col.netWeight)} />
                        <ExcelColumn label="Tonnage" value={col => numberFormatLbs(col.tonnage)} />
                        <ExcelColumn label="Yardage" value={col => numberFormatLbs(col.yardage)} />
                        <ExcelColumn label="Total Order Amount" value={col => numberFormat(col.totalAmount)} />
                        <ExcelColumn label="Service Location" value={col => _.startCase(col.serviceLocation)} />
                        {/* <ExcelColumn label="Payment Type" value={col => _.startCase(col.paymentType)} /> */}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                    {dataSet.length > 0 && pathname === "/queued-for-drivers-signature" && (
                    <ExcelFile hideElement filename="Queued Orders">
                      <ExcelSheet data={dataSet} name="Orders">
                        <ExcelColumn label="Ticket" value="ticketNumber" />
                        <ExcelColumn label="Company Name" value="companyName" />
                        <ExcelColumn label="Cust Ref # " value={col => col.customerRefrence} />
                        <ExcelColumn label="Inbound" value={col => numberFormatLbs(col.inboundWeight)} />
                        <ExcelColumn label="Outbound" value={col => numberFormatLbs(col.outboundWeight)} />
                        <ExcelColumn label="Net Weight" value={col => numberFormatLbs(col.netWeight)} />
                        <ExcelColumn label="Total Order Amount" value={col => numberFormat(col.totalAmount)} />
                        <ExcelColumn label="Payment Type" value={col => _.startCase(col.paymentType)} />
                        <ExcelColumn label="Service Location" value={col => _.startCase(col.serviceLocation)} />
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  {/* <button className="btn btn-dark btn-sm btn-exportorders" onClick={this.showDrawer}>
                    <FilterIcon />
                    Filters {filterCount ? filterCount : ""}
                  </button> */}
                </div>
                {<button
              className="btn btn-dark btn-sm btn-exportorders" onClick={() => { this.setState({ DateRangePickerDroDown: !this.state.DateRangePickerDroDown }) }}>
                <DateIcon />
              {this.state.date.startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} - {this.state.date.endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
            </button>}
            {this.state.DateRangePickerDroDown &&
              <DateRangePicker
              className="datepicker-orders"
                //onChange={(e)=>{this.handleSelect(e.dateRange) ;this.deshboardPayments(e.target.value); this.paymentDatePicker()}}
                onChange={this.handleSelect}
                ranges={[this.state.date]}
                editableDateInputs={true}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                calendarFocus="forwards"
                staticRanges={[
                  ...defaultStaticRanges,
                  {
                    label: "Last Year",
                    range: () => ({
                      startDate: startOfYear(addYears(new Date(), -1)),
                      endDate: endOfYear(addYears(new Date(), -1))
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  },
                  {
                    label: "This Year",
                    range: () => ({
                      startDate: startOfYear(new Date()),
                      endDate: endOfDay(new Date())
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  }
                ]}

              />}
              </div>
              {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.exportOrdersAndReports === true) ?
              <div className="export__section">
                <div className="cursor-pointer d-flex" onClick={this.exportOrder} disabled={progressBar}>
                  <ExportIcon />
                  <h3>{progressBar ? "Please wait..." : "Export Orders"}</h3>
                </div>
              </div>:null
              }
              <div className="row">
                <div className="col-md-12">
                  <div className="filter__button-group">
                    {filterCustomers &&
                      filterCustomers.map(fc => {
                        return (
                          <button className="btn btn-dark btn-sm" key={fc._id}>
                            {fc.companyName}
                            <span>
                              <img
                                className="img-fluid"
                                src={CloseWhite}
                                alt=""
                                onClick={() => this.selectCustomer(fc)}
                              />
                            </span>
                          </button>
                        );
                      })}
                    {filterDrivers &&
                      filterDrivers.map(fd => {
                        return (
                          <button className="btn btn-dark btn-sm" key={fd._id}>
                            {fd.name}
                            <span>
                              <img
                                className="img-fluid"
                                src={CloseWhite}
                                alt=""
                                onClick={() => this.selectDriver(fd)}
                              />
                            </span>
                          </button>
                        );
                      })}
                    {filterUsers &&
                      filterUsers.map(fu => {
                        return (
                          <button className="btn btn-dark btn-sm" key={fu._id}>
                            {fu.name}
                            <span>
                              <img className="img-fluid" src={CloseWhite} alt="" onClick={() => this.selectUser(fu)} />
                            </span>
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="custome__tabs">
                    <ul className="custome__tabs-list">
                      <li>
                        <span
                          className={pathname === "/completed-orders" ? "active" : ""}
                          onClick={() =>
                            {
                            this.setState({isPaymentLater: false  , isFailedPayment : false},()=>{
                              this.onClickTab("completed", "/completed-orders")}
                            )
                            }}
                        >
                          Completed Orders
                        </span>
                      </li>
                      <li>
                        <span
                          className={pathname === "/pending-payments" ? "active" : ""}
                          onClick={() => {
                            this.setState({isPaymentLater: true , isFailedPayment : false},()=>{
                              this.onClickTab("pending-payments", "/pending-payments")
                            })
                          }}
                        >
                          Pending Payments
                        </span>
                      </li>
                      <li>
                        <span
                          className={pathname === "/failed-payments" ? "active" : ""}
                          onClick={() => {
                            this.setState({isPaymentLater: true , isFailedPayment : true},()=>{
                              this.onClickTab("failed-payments", "/failed-payments")
                            })
                          }}
                        >
                          Failed Payments
                          {totalFailedItems > 0 && <div className="tab-notification-count">{totalFailedItems}</div>}
                        </span>
                      </li>
                      <li>
                        <span
                          className={pathname === "/queued-for-drivers-signature" ? "active" : ""}
                          onClick={() =>
                            {
                            this.setState({isPaymentLater: false , isFailedPayment : false},()=>{  
                            this.onClickTab("signature", "/queued-for-drivers-signature")}
                          )}}
                        >
                          Queued for Drivers Signature
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="search__box mb-0">
                <DebounceInput
                  className="form-control"
                  placeholder="Search by company, service location or ticket #..."
                  value={mainSearchText.trimStart()}
                  onChange={e => this.handleMainSearchText(e)}
                  minLength={3}
                  debounceTimeout={500}
                />
                <button onClick={this.showDrawer} className="btn btn-dark btn-sm btn-exportorders">
                  <FilterIcon />
                  Filters {filterCount ? filterCount : ""}
                </button>
              </div>
              <SearchCheckboxComponent
                searchStartsWith={this.state.searchStartsWith}
                handleSearchStartsWith={(value) => this.setState({
                  searchStartsWith: value,
                  skip: 0,
                  currentPage: 1
                }, () => {
                  if(mainSearchText.trim() && mainSearchText.trim()!== "" && mainSearchText.trim().length > 0) {
                    this.fetchOrders()
                  }
                })}
              />
              <br/>
              <div className="row">
                <div className="col-md-12">
                  {pathname === "/completed-orders" ? (
                    <CompletedOrders
                      orders={orders}
                      sortOrder={sortOrder}
                      getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
                      sendInvoiceEmail={this.props.sendInvoiceEmail}
                      updateInvoicDetail={this.props.updateInvoicDetail}
                      getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
                      fetchSetting = {this.props.fetchSetting}
                      getOrderInvoicePdf = {this.props.getOrderInvoicePdf}
                      uploadImage = {this.props.uploadImage}
                      updateInvoiceOrder = {this.props.updateInvoiceOrder}
                      fetchOrder={this.props.fetchOrder}
                      getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
                      history={history}
                      currentPage={this.state.currentPage}
                      setOrder={field => this.setOrder(field)}
                      {...this.props}
                    />
                  ): pathname === "/pending-payments" ? (
                    <PendingPayments
                      orders={orders}
                      sortOrder={sortOrder}
                      history={history}
                      setOrder={field => this.setOrder(field)}
                    />
                  ) : pathname === "/queued-for-drivers-signature" ? (
                    <QueuedForSignature
                      orders={orders}
                      sortOrder={sortOrder}
                      history={history}
                      currentPage={this.state.currentPage}
                      setOrder={field => this.setOrder(field)}
                    />
                  ): (
                    <FailedPayments
                      orders={orders}
                      sortOrder={sortOrder}
                      history={history}
                      currentPage={this.state.currentPage}
                      setOrder={field => this.setOrder(field)}
                    />
                  )}
                </div>
              </div>
              {/*Order Filters*/}
              <Drawer
                title="Basic Drawer"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                className="select__driver-drawer"
              >
                <div className="head-section-header d-flex align-items-center justify-content-between">
                  <button className="btn btn-dark btn-sm clear-btn" onClick={this.clearFilter.bind(this)}>
                    Clear
                  </button>
                  <div className="head-section-title">Filters</div>
                  <div className="head-section-cta">
                    <div onClick={this.onClose} className="btn btn-link btn-back">
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <ul className="filter__list">
                  <li className="filter__list-item">
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={this.openFilterList}
                          name="companyFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.companyFilter}
                        />
                        <span className="checkmark"></span>
                        <p>Companies</p>
                      </label>
                    </span>
                    <div className={`filter__list-item-inner ${this.state.companyFilter ? "show-list-detail" : ""}`}>
                      <div className="form-group material-textfield">
                        <DebounceInput
                          type="text"
                          onChange={this.handleCustomerSearch}
                          value={this.state.companySearchText}
                          className="form-control material-textfield-input"
                          required
                          minLength={3}
                          debounceTimeout={500}
                        />
                        <label className="material-textfield-label">Search companies...</label>
                      </div>
                      <div className="select_driver-wrapper">
                        <div>
                          <ul className="select_driver-list box-shadow">
                            {customers.map(customer => {
                              const fcIndex = _.findIndex(filterCustomers, function(fc) {
                                return String(fc._id) === String(customer._id);
                              });
                              return (
                                <li className={fcIndex !== -1 ? "active" : ""} key={customer._id}>
                                  <div className="d-flex align-items-center">
                                    <p className="driver__name">{_.get(customer, "displayName", "")}</p>
                                  </div>
                                  <label className="custom-checkbox custom-checkbox-line">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      onChange={() => this.selectCustomer(customer)}
                                      checked={fcIndex !== -1 ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="filter__list-item">
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={this.openFilterList}
                          name="driverFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.driverFilter || filterDrivers.length}
                        />
                        <span className="checkmark"></span>
                        <p>Drivers</p>
                      </label>
                    </span>
                    <div
                      className={`filter__list-item-inner ${
                        this.state.driverFilter || filterDrivers.length ? "show-list-detail" : ""
                      }`}
                    >
                      <div className="form-group material-textfield">
                        <DebounceInput
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={this.handleDriverSearch}
                          value={this.state.driverSearchText}
                          minLength={3}
                          debounceTimeout={500}
                          required
                        />
                        <label className="material-textfield-label">Search drivers...</label>
                      </div>
                      <div className="select_driver-wrapper">
                        <div>
                          <ul className="select_driver-list box-shadow">
                            {drivers.map(driver => {
                              const fdIndex = _.findIndex(filterDrivers, function(fd) {
                                return String(fd._id) === String(driver._id);
                              });
                              return (
                                <li className={fdIndex !== -1 ? "active" : ""} key={driver._id}>
                                  <div className="d-flex align-items-center">
                                    <p className="driver__name">
                                      {driver.firstName} {driver.lastName}
                                    </p>
                                  </div>
                                  <label className="custom-checkbox custom-checkbox-line">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      onChange={() => this.selectDriver(driver)}
                                      checked={fdIndex !== -1 ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="filter__list-item">
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={this.openFilterList}
                          name="userFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.userFilter || filterUsers.length}
                        />
                        <span className="checkmark"></span>
                        <p>Users</p>
                      </label>
                    </span>
                    <div
                      className={`filter__list-item-inner ${
                        this.state.userFilter || filterUsers.length ? "show-list-detail" : ""
                      }`}
                    >
                      <div className="form-group material-textfield">
                        <DebounceInput
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={this.handleUserSearch}
                          value={this.state.userSearchText}
                          required
                          minLength={3}
                          debounceTimeout={500}
                        />
                        <label className="material-textfield-label">Search users...</label>
                      </div>
                      <div className="select_driver-wrapper">
                        <div>
                          <ul className="select_driver-list box-shadow">
                            {users.map(user => {
                              const fuIndex = _.findIndex(filterUsers, function(fu) {
                                return String(fu._id) === String(user._id);
                              });
                              return (
                                <li className={fuIndex !== -1 ? "active" : ""} key={user._id}>
                                  <div className="d-flex align-items-center">
                                    <p className="driver__name">
                                      {user.firstName} {user.lastName}
                                    </p>
                                  </div>
                                  <label className="custom-checkbox custom-checkbox-line">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      onChange={() => this.selectUser(user)}
                                      checked={fuIndex !== -1 ? true : false}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  {this.state?.activeTab === "completed" && (
                    <li className="filter__list-item">
                      <span className="d-flex -align-items-center filter__check">
                        <label className="custom-checkbox custom-checkbox-line">
                          <input
                            onChange={this.handleVoidedFilter}
                            name="voidedFilter"
                            type="checkbox"
                            className="custom-checkbox--input"
                            checked={this.state?.isVoided}
                          />
                          <span className="checkmark"></span>
                          <p>Voided</p>
                        </label>
                      </span>
                    </li>
                  )}
                </ul>
              </Drawer>
              {/*Order Filters End*/}
              
              <Pagination
                className="text-center"
                onChange={this.onChangePage}
                hideOnSinglePage
                current={this.state.currentPage}
                total={totalItems}
                pageSize={this.state.limit}
                showSizeChanger={false}
              />
            </Spin>
          </main>
         
           
           
        </div>
      </div>
    );
  }
}
